import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'
import { Button } from 'reactstrap'
import LoadingScreen from 'client/shared/js/components/LoadingScreen'
import FeedItemList from '../FeedItemList'
import NotificationContext from '../../context'
import { trackEvent } from 'client/shared/js/utils/tracking'

const FEED_LIMIT = 10

class NotificationFeed extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    status: PropTypes.object,
    userActions: PropTypes.object,
    onNavigation: PropTypes.func,
    disableInfiniteScroll: PropTypes.bool
  }

  static defaultProps = {
    user: {},
    status: {},
    onNavigation: () => false,
    disableInfiniteScroll: false
  }

  static contextType = NotificationContext

  // Will only set mark_read and mark_seen if there are unread/unseen items
  componentDidMount () {
    const { notification = {} } = this.props.user.feeds
    const incomingNotifications = this.context.incomingNotifications
    const unread = (incomingNotifications.length > 0 || notification.unread > 0)
    const unseen = (incomingNotifications.length > 0 || notification.unseen > 0)

    const notificationOptions = {
      ...((unread) ? { mark_read: true } : {}),
      ...((unseen) ? { mark_seen: true } : {})
    }

    this.context.syncNotifications(notificationOptions)
  }

  // Always sync notification on show and hide. This is necessary because of
  //  how getstream always returns read/seen as false on first time load
  // See: https://getstream.io/docs/#notification-feeds
  componentWillUnmount () {
    this.context.syncNotifications()
  }

  loadMoreNotifications = (pageNum) => {
    const offset = FEED_LIMIT * pageNum

    trackEvent('Load more notifications', {
      'Notifications page number': pageNum
    })

    this.context.syncNotifications({
      limit: FEED_LIMIT,
      offset
    })
  }

  // Incoming notifictions are always unread and unseen
  handleIncomingNotificationsClick = () => {
    trackEvent('Load incoming notifications')
    this.context.syncNotifications({
      mark_read: true,
      mark_seen: true
    })
  }

  // Render an incoming notifications indicator if more notifications were created
  renderIncomingNotifications = () => {
    const incomingNotifications = this.context.incomingNotifications
    const isLoading = this.props.status.syncNotifications

    if (isLoading) {
      return (
        <LoadingScreen loader='beat' size={12} />
      )
    } else if (incomingNotifications.length > 0) {
      const updatesTxt = (incomingNotifications.length === 1) ? 'new update' : 'new updates'

      return (
        <Button
          onClick={this.handleIncomingNotificationsClick}
          color='secondary'
          className='load-notifications-btn square flat'
          block>
          Load <b>{incomingNotifications.length}</b> {updatesTxt}
        </Button>
      )
    }

    return null
  }

  // TODO: improve aggregated event handling
  renderFeedItems = (feedResults) => {
    const feedItems = (
      <FeedItemList
        user={this.props.user}
        userActions={this.props.userActions}
        onNavigation={this.props.onNavigation}
        status={this.props.status}
        feed={feedResults} />
    )

    // Only fetch if we're not currently fetching and there is a `next` value in the notification feed
    const hasMore = (!!this.props.user.feeds.notification.next && !this.props.status.syncNotifications)

    return (!this.props.disableInfiniteScroll) ? (
      <InfiniteScroll
        element='ul'
        className='feed-ul notification-feed'
        pageStart={0}
        loadMore={this.loadMoreNotifications}
        hasMore={hasMore}
        loader={(<LoadingScreen loader='beat' size={12} key={0} />)}>
        {feedItems}
      </InfiniteScroll>
    ) : (
      <ul className='feed-ul notification-feed'>
        {feedItems}
      </ul>
    )
  }

  renderNotificationFeed = () => {
    const notificationFeed = this.props.user.feeds.notification
    const feedResults = notificationFeed.results || []

    if (feedResults.length > 0) {
      return this.renderFeedItems(feedResults)
    } else if (!this.props.status.syncNotifications) {
      return (<h6 className='text-center m-4'>You have no new notifications</h6>)
    }

    return null
  }

  render () {
    return (
      <div className='user-notifications-container'>
        {this.renderIncomingNotifications()}
        {this.renderNotificationFeed()}
      </div>
    )
  }
}

export default NotificationFeed
