import { normalizeBoardAttrs } from 'client/shared/js/services/boards/schema'
import { entityKey as suggestionsEntityKey } from './suggestions'

const entityKey = 'search'

export const normalizeSearchSuggestionsForBoard = (boardId, searchSuggestionsData) => {
  return normalizeBoardAttrs(boardId, {
    [entityKey]: {
      [suggestionsEntityKey]: searchSuggestionsData
    }
  })
}
