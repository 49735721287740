import Loadable from 'client/shared/js/components/Loadable'

const PublicLocationsSitemap = Loadable(() => import(
  /* webpackChunkName: "LocationsSitemap" */
  '../Sitemap/Locations'
))

const PublicBoardsSitemap = Loadable(() => import(
  /* webpackChunkName: "PublicBoardsSitemap" */
  '../Sitemap/Boards'
))

export const routes = () => [
  {
    path: `/sitemap/locations/:pageNumber?/:size?`,
    exact: true,
    track: 'Public Locations Sitemap',
    component: PublicLocationsSitemap
  },
  {
    path: `/sitemap/locations/:locationText?/boards/page/:pageNumber?/:size?`,
    exact: true,
    track: 'Public Boards Sitemap',
    component: PublicBoardsSitemap
  }
]

export default routes
