import React, { useMemo } from 'react'
import { compose } from 'redux'

/**
 * Returns nested Context.Provider for each context-value pair in the array
 * @param {Array} props.contexts Array of objects with 'context' and 'value' keys
 */
const ContextsProvider = ({ contexts = [], children = null }) => {
  const providers = useMemo(() => contexts.map(({ context, value }) =>
    (children) => <context.Provider value={value} children={children} />
  ), [contexts])

  return compose(...providers)(children)
}

export default ContextsProvider
