
import AppContext from 'client/shared/js/contexts/app'
import { NavLinksContext } from 'client/shared/js/components/Navbar'
import { NotificationsContext } from '../../components/Notification'

export const getContexts = ({
  selectors,
  navigate,
  navLinks,
  setNavLinks,
  incomingNotifications,
  setIncomingNotifications,
  clearIncomingNotifications,
  syncNotifications
}) => [
  {
    context: AppContext,
    value: {
      selectors,
      navigate
    }
  },
  {
    context: NavLinksContext,
    value: {
      navLinks,
      setNavLinks
    }
  },
  {
    context: NotificationsContext,
    value: {
      incomingNotifications,
      setIncomingNotifications,
      clearIncomingNotifications,
      syncNotifications
    }
  }
]
