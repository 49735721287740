import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import FollowFeedItem, { FollowVerbs } from './components/FollowFeedItem'
import BoardFeedItem, { BoardVerbs } from './components/BoardFeedItem'
import './FeedItem.scss'

const FeedItem = (props) => {
  const { verb } = props
  let feedItemComponent = null

  if (FollowVerbs[verb]) {
    feedItemComponent = (<FollowFeedItem {...props} />)
  } else if (BoardVerbs[verb]) {
    feedItemComponent = (<BoardFeedItem {...props} />)
  }

  const listClass = classNames('feed-item', {
    unread: props.unread
  })

  return (feedItemComponent) ? (
    <li className={listClass}>
      {feedItemComponent}
    </li>
  ) : null
}

FeedItem.propTypes = {
  verb: PropTypes.string.isRequired,
  unread: PropTypes.bool
}

export default FeedItem
