import {
  UPDATE_ACCOUNT_SETTINGS
} from '../types'
import { checkAndRefreshUserToken } from '../../auth/actions'
import { API_ERROR, SUCCESS_MESSAGE } from '../../globalMessage/types'
import userApi from '../api'

export const updateAccountSettings = (userId, accountSettingsData) => {
  return (dispatch) => {
    return dispatch(checkAndRefreshUserToken())
      .then(() => {
        return userApi.updateAccountSettings(userId, accountSettingsData)
          .then((res) => {
            const result = res.data

            dispatch({
              type: UPDATE_ACCOUNT_SETTINGS,
              data: result,
              track: {
                event: {
                  name: 'Account privacy settings changed'
                }
              }
            })
            dispatch({
              type: SUCCESS_MESSAGE,
              message: 'Your account has been successfully updated'
            })
          })
      })
      .catch((err) => {
        dispatch({
          type: API_ERROR,
          message: 'We ran into an issue updating your account settings.'
        })
      })
  }
}
