import { createMiddleware } from 'redux-beacon'
import { trackUniversalEvent } from 'client/shared/js/utils/tracking'

const mixpanelTarget = (eventDefinitions) => {
  eventDefinitions.forEach((currDef) => {
    trackUniversalEvent(currDef)
  })
}

const sendMultipleEvents = (action) => {
  const eventDefinitions = []

  if (action.track) {
    if (action.track.user) {
      eventDefinitions.push((action) => ({ user: action.track.user }))
    }

    if (action.track.page) {
      eventDefinitions.push((action) => ({ page: action.track.page }))
    }

    if (action.track.event) {
      eventDefinitions.push((action) => ({ event: action.track.event }))
    }

    if (action.track.board) {
      eventDefinitions.push((action) => ({ board: action.track.board }))
    }
  }

  return eventDefinitions
}

const reduxEventsMapper = (pageName) => {
  return (action) => {
    switch (action.type) {
      default:
        return sendMultipleEvents(action)
    }
  }
}

export default (pageName) => {
  const eventsMapper = reduxEventsMapper(pageName)

  return createMiddleware(eventsMapper, mixpanelTarget)
}
