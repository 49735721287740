import * as TYPES from './types'
import * as schema from 'client/shared/js/services/users/schema'

export const beginFetchUser = (userId, username, options = {}) => ({
  type: TYPES.BEGIN_FETCH_USER,
  data: {
    uid: userId,
    username,
    options
  }
})

export const fetchUserSuccess = (userData) => ({
  type: TYPES.FETCH_USER_SUCCESS,
  data: schema.normalizeUser(userData)
})

// Noop, for future proofing
export const fetchUserError = () => ({
  type: TYPES.FETCH_USER_ERROR
})
