import axios from 'axios'
import queryString from 'query-string'
import apiConfig from 'common/configs/api'
import { getHeaders } from 'client/shared/js/services/auth/api'

const { apiUrl, usernames } = apiConfig
const usernamesUrl = `${apiUrl}${usernames.url}`
const includeUserProfileAndBoardsQuery = queryString.stringify({
  includeProfile: true,
  includeBoards: true
})

export const getUserFromUsername = (username, options) => {
  const query = (options) ? queryString.stringify(options) : includeUserProfileAndBoardsQuery
  const url = `${usernamesUrl}/${username}?${query}`
  const headers = getHeaders()

  return axios.get(url, {
    headers
  })
}

export default {
  getUserFromUsername
}
