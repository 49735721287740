import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FormGroup, Label } from 'reactstrap'
import GooglePlacesAutoComplete from 'client/shared/js/components/GooglePlacesAutoComplete'
import styles from '../../index.module.scss'

export const propTypes = {
  mountId: PropTypes.string,
  location: PropTypes.object,
  maps: PropTypes.object.isRequired,
  setLocation: PropTypes.func,
  label: PropTypes.node,
  placeholder: PropTypes.string
}

export const defaultProps = {
  placeholder: 'Portland, Oregon',
  label: <>Where do you want to go? <span className='danger'>*</span></>
}

const LocationFormGroup = ({ mountId, location, maps, setLocation, label, placeholder }) => {
  const place = { location }

  const handleGooglePlacesChange = (place) => {
    const { location } = place

    setLocation(location)
  }

  return (
    <FormGroup className={cx(styles['form-group'])}>
      {label && (
        <Label
          for={`placesAutocomplete-${mountId}`}
          className={cx(styles['label'])}>
          {label}
        </Label>
      )}
      <GooglePlacesAutoComplete
        id={`placesAutocomplete-${mountId}`}
        place={place}
        placeholder={placeholder}
        maps={maps}
        type='city'
        onInputChange={handleGooglePlacesChange}
        onSuggestionSelected={handleGooglePlacesChange}
        disableAttribution />
    </FormGroup>
  )
}

LocationFormGroup.propTypes = propTypes
LocationFormGroup.defaultProps = defaultProps

export default LocationFormGroup
