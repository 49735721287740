export const sexagesimalToDecimal = (degree = 0, min = 0, sec = 0, ref) => {
  let decimal = (parseFloat(degree) + parseFloat(min / 60) + parseFloat(sec / 3600)).toFixed(8)

  // South and West are negative decimals
  if (ref === 'S' || ref === 'W') {
    return (-decimal)
  }

  return decimal
}
