import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import nanoid from 'nanoid'
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Popover
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatMobileDatePickerDate, unformatMobileDatePickerDate } from 'client/shared/js/components/Date/utils'
import styles from './MobileDatePicker.module.scss'

const DATE_PICKER_TITLE = 'Edit dates'

const propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  target: Popover.propTypes.target,
  placement: PropTypes.string,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  onDateChange: PropTypes.func,
  showClearButton: PropTypes.bool
}

export const MobileDatePicker = ({
  isOpen,
  toggle,
  target,
  placement,
  startDate: startDateProp,
  endDate: endDateProp,
  onDateChange,
  showClearButton
}) => {
  const uid = useMemo(() => nanoid(), [])
  const [startDateValue, setStartDateValue] = useState(formatMobileDatePickerDate(startDateProp))
  const [endDateValue, setEndDateValue] = useState(formatMobileDatePickerDate(endDateProp))

  const clearDates = () => {
    setStartDateValue('')
    setEndDateValue('')
  }

  const handleStartDateChange = (e) => {
    setStartDateValue(e.target.value)
  }

  const handleEndDateChange = (e) => {
    setEndDateValue(e.target.value)
  }

  const handleSave = () => {
    onDateChange({
      startDate: unformatMobileDatePickerDate(startDateValue),
      endDate: unformatMobileDatePickerDate(endDateValue)
    })
    toggle()
  }

  return (
    <Popover
      isOpen={isOpen}
      target={target}
      toggle={toggle}
      placement={placement}
      fade={false}>
      <div className={cx(styles['inner'], 'd-flex pt-2 pb-4')}>
        <h4 className='mr-auto'>{DATE_PICKER_TITLE}</h4>
        <Button className={cx('dark close', styles['close-btn'])} color='link' onClick={toggle}>
          <FontAwesomeIcon icon={['fal', 'times']} />
        </Button>
      </div>
      <Form className={cx(styles['inner'], styles['datepicker-form'])}>
        <FormGroup>
          <Label for={`start-date-${uid}`}>Start date</Label>
          <Input type='date' id={`start-date-${uid}`} onChange={handleStartDateChange} value={startDateValue} />
        </FormGroup>
        <FormGroup>
          <Label for={`end-date-${uid}`}>End date</Label>
          <Input type='date' id={`end-date-${uid}`} onChange={handleEndDateChange} value={endDateValue} />
        </FormGroup>
        <FormGroup className='mt-4 d-flex'>
          {showClearButton ? (
            <Button color='link mr-auto' onClick={clearDates}>Clear</Button>
          ) : null}
          <Button onClick={handleSave}>Save</Button>
        </FormGroup>
      </Form>
    </Popover>
  )
}

MobileDatePicker.propTypes = propTypes
