export const FOLLOW_USER = 'FOLLOW_USER'
export const FOLLOW_BOARD = 'FOLLOW_BOARD'
export const FOLLOW_REQUEST = 'FOLLOW_REQUEST'
export const FOLLOW_APPROVED = 'FOLLOW_APPROVED'

export const BOARD_CREATED = 'BOARD_CREATED'
export const BOARD_UPDATED = 'BOARD_UPDATED'
export const BOARD_DELETED = 'BOARD_DELETED'
export const BOARD_ADD_COLLABORATOR = 'BOARD_ADD_COLLABORATOR'

export const USER_ADD_COLLABORATOR_TO_BOARD = 'USER_ADD_COLLABORATOR_TO_BOARD'
