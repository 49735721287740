import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './CharacterCountWrapper.scss'

class CharacterCountWrapper extends Component {
  componentDidUpdate () {
    if (this.isOverflow()) {
      this.props.onOverflow()
    }
  }

  isOverflow = () => {
    return (this.props.current > this.props.max)
  }

  render () {
    const isOverflow = this.isOverflow()

    const characterCountClass = classNames({
      'character-count-wrapper': true,
      'error': isOverflow
    })

    return (
      <div className={characterCountClass}>
        {this.props.children}
        <div className='character-counter'>{this.props.current}/{this.props.max}</div>
      </div>
    )
  }
}

CharacterCountWrapper.defaultProps = {
  onOverflow: () => false,
  current: 0,
  max: 0
}

CharacterCountWrapper.propTypes = {
  onOverflow: PropTypes.func,
  current: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  children: PropTypes.node
}

export default CharacterCountWrapper
