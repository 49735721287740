import {
  GET_STREAM_TOKENS,
  FETCH_NOTIFICATION_FEED,
  LOAD_MORE_NOTIFICATIONS,
  REMOVE_NOTIFICATION_FEED_ITEM,
  REMOVE_NOTIFICATION_FEED_GROUP
} from '../types'
import { checkAndRefreshUserToken } from '../../auth/actions'
import { API_ERROR } from '../../globalMessage/types'
import { UPDATE_STATUS } from 'client/shared/js/services/status/types'
import userApi from '../api'

export const getStreamTokens = (userId) => {
  return (dispatch) => {
    return dispatch(checkAndRefreshUserToken())
      .then(() => {
        return userApi.getStreamTokens(userId)
      })
      .then((res) => {
        dispatch({
          type: GET_STREAM_TOKENS,
          data: res.data
        })
      })
      .catch((err) => {
        dispatch({
          type: API_ERROR,
          message: 'Our bad, we can\'t seem to fetch your user feed.'
        })
      })
  }
}

export const fetchNotificationFeed = (userId, options = {}) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_STATUS,
      status: {
        syncNotifications: true
      }
    })

    return dispatch(checkAndRefreshUserToken())
      .then(() => {
        return userApi.getNotificationFeed(userId, options)
      })
      .then((res) => {
        const notificationFeed = res.data

        // If offset is provided and is greater than 0,
        // then we are loading more notifications
        if (options.offset > 0) {
          dispatch({
            type: LOAD_MORE_NOTIFICATIONS,
            data: notificationFeed
          })
        } else {
          dispatch({
            type: FETCH_NOTIFICATION_FEED,
            data: notificationFeed
          })
        }
      })
      .then(() => {
        dispatch({
          type: UPDATE_STATUS,
          status: {
            syncNotifications: false
          }
        })
      })
      .catch((err) => {
        dispatch({
          type: API_ERROR,
          message: 'Our bad, we can\'t seem to fetch your notifications.'
        })
      })
  }
}

export const removeNotificationActivity = (userId, activityId) => {
  return (dispatch) => {
    return dispatch(checkAndRefreshUserToken())
      .then(() => {
        return userApi.deleteNotificationActivity(userId, activityId)
      })
      .then((res) => {
        const { removed } = res.data

        dispatch({
          type: REMOVE_NOTIFICATION_FEED_ITEM,
          data: removed
        })
      })
      .catch((err) => {
        dispatch({
          type: API_ERROR,
          message: 'Our bad, we can\'t seem to delete this notification activity.'
        })
      })
  }
}

export const removeNotificationGroup = (userId, groupId, activityIds) => {
  return (dispatch) => {
    return dispatch(checkAndRefreshUserToken())
      .then(() => {
        return userApi.deleteNotificationActivities(userId, { activityIds })
      })
      .then((res) => {
        dispatch({
          type: REMOVE_NOTIFICATION_FEED_GROUP,
          data: {
            ...res.data,
            groupId
          }
        })
      })
      .catch((err) => {
        dispatch({
          type: API_ERROR,
          message: 'Our bad, we can\'t seem to delete this notification activity.'
        })
      })
  }
}
