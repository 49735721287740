import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import EditBoardModal from 'client/app/js/components/EditBoardModal'
import { useModal } from 'client/app/js/components/Modal'

export const defaultProps = {
  board: {},
  status: {},
  className: '',
  container: 'div',
  buttonColor: 'primary',
  buttonSize: '',
  buttonClass: '',
  closeOnSave: false,
  displayButton: true,
  onClick: () => false
}

export const propTypes = {
  board: PropTypes.object,
  status: PropTypes.object,
  maps: PropTypes.object.isRequired,
  match: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  container: PropTypes.string,
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  buttonColor: PropTypes.string,
  buttonSize: PropTypes.string,
  buttonClass: PropTypes.string,
  modalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  saveText: PropTypes.string,
  deleteText: PropTypes.string,
  closeOnSave: PropTypes.bool,
  disableName: PropTypes.bool,
  children: PropTypes.node
}

const EditBoardModalButton = (props) => {
  const [isOpen, toggleModal] = useModal('Open Board Edit Modal', { 'Type': props.saveText })

  const handleClick = () => {
    props.onClick()
    toggleModal()
  }

  return React.createElement(
    props.container,
    { className: props.className },
    (
      <>
        <Button
          title={props.modalTitle}
          color={props.buttonColor}
          className={props.buttonClass}
          size={props.buttonSize}
          onClick={handleClick}>
          {props.buttonText}
        </Button>
        <EditBoardModal
          isOpen={isOpen}
          toggle={handleClick}
          modalTitle={props.modalTitle}
          children={props.children}
          closeOnSave={props.closeOnSave}
          board={props.board}
          maps={props.maps}
          match={props.match}
          status={props.status}
          onCancel={toggleModal}
          onSave={props.onSave}
          onDelete={props.onDelete}
          saveText={props.saveText}
          deleteText={props.deleteText}
          disableName={props.disableName} />
      </>
    )
  )
}

EditBoardModalButton.defaultProps = defaultProps
EditBoardModalButton.propTypes = propTypes

export default EditBoardModalButton
