import React from 'react'
import PropTypes from 'prop-types'
import { getBoardRoute } from 'common/configs/routeMap'
import ItemActivity from '../ItemActivity'
import Link from 'client/shared/js/components/Link'
import {
  BOARD_CREATED,
  BOARD_UPDATED,
  FOLLOW_BOARD,
  BOARD_ADD_COLLABORATOR,
  USER_ADD_COLLABORATOR_TO_BOARD
} from 'client/shared/js/enums/getstream/verbs'

export const Verbs = {
  [BOARD_CREATED]: BOARD_CREATED,
  [BOARD_UPDATED]: BOARD_UPDATED,
  [FOLLOW_BOARD]: FOLLOW_BOARD,
  [BOARD_ADD_COLLABORATOR]: BOARD_ADD_COLLABORATOR,
  [USER_ADD_COLLABORATOR_TO_BOARD]: USER_ADD_COLLABORATOR_TO_BOARD
}

export const FeedMessage = (message, options = {}) => {
  const { sharedBoardId, title = 'a board' } = options.board || {}
  const boardUrl = (sharedBoardId) ? getBoardRoute({ sharedBoardId }) : ''
  const BoardLink = (boardUrl) ? (
    <Link to={boardUrl} onClick={options.onClick}>
      {title}
    </Link>
  ) : (
    <span>{title}</span>
  )

  return (
    <>
      <span>{message} </span>
      {BoardLink}
    </>
  )
}

const BoardFeedItem = (props) => {
  const { object, verb } = props.activity
  const board = object.data
  let message = ''
  let options = {
    board,
    onClick: props.onNavigation
  }

  switch (verb) {
    case BOARD_CREATED:
      message = FeedMessage('has created a new board', options)
      break
    case BOARD_UPDATED:
      message = FeedMessage('has updated:', options)
      break
    case USER_ADD_COLLABORATOR_TO_BOARD:
      message = FeedMessage('has added you as a collaborator to', options)
      break
    case FOLLOW_BOARD:
      message = FeedMessage('has favorited:', options)
      break
    case BOARD_ADD_COLLABORATOR:
      message = FeedMessage('has been added as a collaborator to', options)
      break
    default:
      message = ''
  }

  return (message) ? (
    <ItemActivity {...props} message={message} />
  ) : null
}

BoardFeedItem.propTypes = {
  user: PropTypes.object.isRequired,
  userActions: PropTypes.object.isRequired,
  status: PropTypes.object.isRequired,
  activity: PropTypes.object.isRequired,
  onNavigation: PropTypes.func
}

export default BoardFeedItem
