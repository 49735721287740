import { schema, normalize } from 'normalizr'
import {
  board
} from 'client/shared/js/services/boards/schema'
import {
  list,
  idAttribute as listIdAttribute,
  entityKey as listEntityKey
} from 'client/shared/js/services/lists/schema'
import {
  listPlace,
  idAttribute as listPlaceIdAttribute
} from 'client/shared/js/services/listPlaces/schema'

// Since listPlaces api responses have peculiar format, redefine schema tailored to them
const listCRUD = new schema.Entity(listEntityKey, { listPlace }, {
  idAttribute: listIdAttribute,
  mergeStrategy: (entityA, entityB) => {
    const entity = { ...entityA, ...entityB }
    delete entity.listPlace

    return entity
  }
})

const placeCRUD = {
  [listIdAttribute]: listCRUD
}

const reorderPlacesBetweenLists = {
  board,
  listPlaces: [listPlace],
  source: list,
  destination: list
}

/**
 * Normalizes addPlace and updatePlace action data
 * @param {Object} addPlaceData The place data
 *
 * addPlaceData = {
 *   lid,
 *   listPlace: {
 *     lpid
 *   }
 * }
 *
 * output = {
 *   entities: {
 *     listPlaces,
 *     lists
 *   },
 *   result: {
 *     lid,
 *     lpid
 *   }
 * }
 */
export const normalizeAddPlace = (addPlaceData) => {
  const { lpid } = addPlaceData.listPlace

  const data = {
    [listIdAttribute]: addPlaceData
  }

  const normData = normalize(data, placeCRUD)

  if (lpid) normData.result[listPlaceIdAttribute] = lpid

  return normData
}

export const normalizeUpdatePlace = normalizeAddPlace

/**
 * Normalizes deletePlace action data
 * @param {String} listId The list id
 * @param {String} listPlaceId The listPlace id
 *
 * output = {
 *   entities: {
 *     listPlaces,
 *     lists
 *   },
 *   result: {
 *     lid,
 *     lpid
 *   }
 * }
 */
export const normalizeDeletePlace = (listId, listPlaceId) => {
  return {
    entities: {},
    result: {
      [listIdAttribute]: listId,
      [listPlaceIdAttribute]: listPlaceId
    }
  }
}

/**
 * Normalizes reorderPlacesBetweenLists action data
 * @param {String} reorderPlacesData The reorderPlacesBetweenLists action data
 *
 * reorderPlacesData = {
 *   listOrder,
 *   listPlaces,
 *   source: {
 *     lid
 *   },
 *   destination: {
 *     lid
 *   }
 * }
 *
 * output = {
 *   entities: {
 *     boards,
 *     listPlaces,
 *     lists
 *   },
 *   result: {
 *     bid,
 *     lid,
 *     listPlaces,
 *     source,
 *     destination
 *   }
 * }
 */
export const normalizeReorderPlacesBetweenLists = (bid, reorderPlacesData) => {
  const { listOrder } = reorderPlacesData
  const data = {
    ...((listOrder) ? { board: { bid, listOrder } } : {}),
    ...reorderPlacesData
  }

  delete data.listOrder
  data.source.placesOrder = data.source.placesOrder ?? []
  data.destination.placesOrder = data.destination.placesOrder ?? []

  // This inserts destination lid to listPlace entity
  data[listIdAttribute] = data.destination[listIdAttribute]

  return normalize(data, reorderPlacesBetweenLists)
}
