import nanoid from 'nanoid'
import isInteger from 'lodash/isInteger'
import {
  CREATE_LIST,
  BEGIN_UPDATE_LIST,
  COMMIT_UPDATE_LIST,
  PREEMPT_UPDATE_LIST_ERROR,
  UPDATE_LIST_SUCCESS,
  UPDATE_LIST_ERROR,
  COMMIT_DELETE_LIST
} from './types'
import {
  BEGIN_UPDATE_LIST_ORDER,
  COMMIT_UPDATE_LIST_ORDER,
  COLLABORATOR_UPDATE_LIST
} from '../boards/types'
import {
  LIST_UPDATE_CONFLICT,
  BOARD_NOT_FOUND,
  LIST_NOT_FOUND
} from 'client/shared/js/enums/errors'
import { API_ERROR } from 'client/shared/js/services/globalMessage/types'
import { CLEAR_LIST_STATUS } from 'client/shared/js/services/status/types'
import { checkAndRefreshUserToken } from 'client/shared/js/services/auth/actions'
import { apiError } from 'client/shared/js/services/globalMessage/actions'
import boardsApi from '../boards/api'
import { trackBoard } from 'client/shared/js/utils/tracking'
import * as listsActionsSchema from './schema'
import * as boardsSchema from 'client/shared/js/services/boards/schema'
import * as listsSchema from 'client/shared/js/services/lists/schema'
import * as statusSchema from 'client/shared/js/services/status/schema'

const schema = {
  ...listsActionsSchema,
  ...boardsSchema,
  ...listsSchema,
  ...statusSchema
}

export const createList = (board, list, index) => {
  return (dispatch) => {
    const boardId = board.bid
    const listId = nanoid()
    const listOrder = [...board.listOrder]
    const isValidIndex = isInteger(index)

    if (isValidIndex) {
      listOrder.splice(index, 0, listId)
    } else {
      listOrder.push(listId)
    }

    const boardData = { listOrder }
    const listData = { ...list }

    dispatch({
      type: CREATE_LIST,
      data: schema.normalizeCreateList(boardId, listId, boardData, listData),
      track: {
        board: {
          name: 'List Created for Board',
          props: {
            lid: listId
          },
          data: board
        }
      },
      // Set local timestamp, which will be updated later
      meta: {
        createdDate: new Date().getTime(),
        updatedDate: new Date().getTime()
      }
    })

    const listDataToSave = {
      ...(isValidIndex ? { index } : {}),
      list: {
        ...list,
        lid: listId
      }
    }

    return dispatch(checkAndRefreshUserToken())
      .then(() => {
        return boardsApi.createList(boardId, listId, listDataToSave)
          .catch((error) => {
            const code = error.response?.data?.type

            if (code === BOARD_NOT_FOUND) {
              let message = 'The board you were editing no longer exists.'

              dispatch({
                type: API_ERROR,
                style: 'modal',
                message
              })
            } else {
              let message = 'Whoops, we couldn\'t create your new list.'

              dispatch({
                type: API_ERROR,
                message
              })
            }
          })
      })
  }
}

export const beginUpdateList = (board, listId, listData, options = {}) => ({
  type: BEGIN_UPDATE_LIST,
  data: {
    lid: listId,
    ...listData
  },
  track: {
    board: {
      name: 'List updated',
      props: {
        lid: listId
      },
      data: board
    }
  },
  options
})

export const commitUpdateList = (listData) => ({
  type: COMMIT_UPDATE_LIST,
  data: schema.normalizeList(listData)
})

export const collaboratorUpdateList = (listData) => ({
  type: COLLABORATOR_UPDATE_LIST,
  data: schema.normalizeList(listData)
})

export const preemptUpdateListError = (listId, listData) => ({
  type: PREEMPT_UPDATE_LIST_ERROR,
  error: {
    type: LIST_UPDATE_CONFLICT
  },
  data: schema.normalizeListStatus(listId, { sent: listData })
})

export const updateListSuccess = (listData) => ({
  type: UPDATE_LIST_SUCCESS,
  data: schema.normalizeList(listData)
})

export const updateListError = (listId, error) => ({
  type: UPDATE_LIST_ERROR,
  data: schema.normalizeListAttrs(listId),
  error
})

export const clearListStatus = (listId) => ({
  type: CLEAR_LIST_STATUS,
  data: schema.normalizeListAttrs(listId)
})

export const beginUpdateListOrder = (board, listOrder) => {
  const boardId = board.bid

  return (dispatch) => {
    dispatch({
      type: BEGIN_UPDATE_LIST_ORDER,
      track: {
        board: {
          name: 'List Reordered',
          data: board
        }
      }
    })

    dispatch(commitUpdateListOrder(boardId, listOrder))

    return dispatch(checkAndRefreshUserToken())
      .then(() => {
        return boardsApi.updateListOrder(boardId, { listOrder })
          .catch((error) => {
            const code = error.response?.data?.type

            if (code === BOARD_NOT_FOUND) {
              let message = 'The board you were editing no longer exists.'

              dispatch({
                type: API_ERROR,
                style: 'modal',
                message
              })
            } else {
              let message = 'Whoops, we couldn\'t reorder your lists.'

              dispatch({
                type: API_ERROR,
                message
              })
            }
          })
      })
  }
}

export const commitUpdateListOrder = (boardId, listOrder) => ({
  type: COMMIT_UPDATE_LIST_ORDER,
  data: schema.normalizeBoardAttrs(boardId, { listOrder })
})

export const deleteList = (board, listId, listOrderIndex) => {
  return (dispatch) => {
    trackBoard('List Deleted', { lid: listId }, board)

    const boardId = board.bid

    dispatch(commitDeleteList(listId))

    return dispatch(checkAndRefreshUserToken())
      .then(() => {
        return boardsApi.deleteList(boardId, listId)
          .catch((error) => {
            const code = error.response?.data?.type

            if (code === BOARD_NOT_FOUND) {
              let message = 'The board you were editing no longer exists.'
              dispatch(apiError(message, 'modal'))
            } else if (code !== LIST_NOT_FOUND) {
              let message = 'Whoops, we couldn\'t delete your list.'
              dispatch(apiError(message))
            }
          })
      })
  }
}

export const commitDeleteList = (listId) => ({
  type: COMMIT_DELETE_LIST,
  data: schema.normalizeListAttrs(listId)
})
