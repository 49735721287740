import * as TYPES from './types'
import * as schema from 'client/shared/js/services/places/schema'

export const beginFetchPlaceDetails = (gpid) => ({
  type: TYPES.BEGIN_FETCH_PLACE_DETAILS,
  data: gpid
})

export const fetchPlaceDetailsSuccess = (gpid, placeDetailsData) => ({
  type: TYPES.FETCH_PLACE_DETAILS_SUCCESS,
  data: schema.normalizePlace(gpid, placeDetailsData),
  track: {
    event: {
      name: 'Place details requested',
      props: {
        gpid
      }
    }
  }
})
