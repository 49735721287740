import * as TYPES from './types'

export const apiError = (message, style) => {
  const action = {
    type: TYPES.API_ERROR,
    message
  }

  if (style) action.style = style

  return action
}

export const errorMessage = (message) => ({
  type: TYPES.ERROR_MESSAGE,
  message
})

export const successMessage = (message, eventName) => {
  const action = {
    type: TYPES.SUCCESS_MESSAGE,
    message
  }

  if (eventName) action.event = { name: eventName }

  return action
}

export const fileUploadError = (message, eventName) => {
  const action = {
    type: TYPES.FILE_UPLOAD_ERROR,
    message
  }

  if (eventName) action.event = { name: eventName }

  return action
}
