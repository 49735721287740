import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Container, Row, Col, Card } from 'reactstrap'
import { Page } from 'client/shared/js/components/Page'
import styles from './CardPage.module.scss'

export const defaultProps = {}

export const propTypes = {
  header: PropTypes.node,
  children: PropTypes.node,
  pageClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  cardClassName: PropTypes.string,
  blob: PropTypes.string
}

const CardPage = ({ blob, ...props }) => {
  const Blob = useMemo(() => (
    (blob) ? (
      <div className={cx(styles['blob'], 'blob')} style={{
        backgroundImage: `url('${blob}')`
      }} />
    ) : null
  ), [blob])

  return (
    <Page className={cx(styles['page'], props.pageClassName)}>
      {props.header}
      <div className={cx(styles['card-wrapper'], props.wrapperClassName)}>
        <Container className='position-relative'>
          {Blob}
          <Row className='justify-content-center'>
            <Col md={{ size: 10 }} lg={{ size: 7 }}>
              <Card className={cx(styles['card'], props.cardClassName)}>
                {props.children}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Page>
  )
}

CardPage.defaultProps = defaultProps
CardPage.propTypes = propTypes

export default CardPage
