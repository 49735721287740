import React from 'react'
import PropTypes from 'prop-types'
import {
  FeedItem,
  AggregatedFeedItem,
  AggregatedVerbs
} from 'client/app/js/components/FeedItem'

const FeedItemList = (props) => {
  const { feed } = props
  let feedItems = []

  feed.forEach((feedItem, index) => {
    const { id, verb, activities } = feedItem
    const unread = !feedItem['is_read']
    const feedItemProps = {
      verb,
      unread,
      user: props.user,
      userActions: props.userActions,
      onNavigation: props.onNavigation,
      status: props.status
    }

    // Do not aggregate if there is only one activity and it is not a valid aggregate verb
    if (AggregatedVerbs[verb] && activities.length > 1) {
      feedItems.push(
        <AggregatedFeedItem
          groupId={id}
          key={`notification-feed-items-${index}`}
          activities={activities}
          {...feedItemProps} />
      )
    } else {
      // Renders individual feed items from a list of activites
      const feedItemComponents = activities.map((activity) => (
        <FeedItem
          key={`notification-feed-item-${activity.id}`}
          activity={activity}
          {...feedItemProps} />
      ))

      feedItems.push(...feedItemComponents)
    }
  })

  return feedItems
}

FeedItemList.propTypes = {
  user: PropTypes.object,
  userActions: PropTypes.object,
  onNavigation: PropTypes.func,
  status: PropTypes.object
}

export default FeedItemList
