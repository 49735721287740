import * as UserActions from './user'
import * as AccountActions from './account'
import * as ProfileActions from './profile'
import * as SettingsActions from './settings'
import * as FollowsActions from './follows'
import * as FeedActions from './feeds'

export default {
  ...UserActions,
  ...AccountActions,
  ...ProfileActions,
  ...SettingsActions,
  ...FollowsActions,
  ...FeedActions
}
