import {
  UPDATE_USER_PROFILE
} from '../types'
import { checkAndRefreshUserToken } from '../../auth/actions'
import { API_ERROR, SUCCESS_MESSAGE } from '../../globalMessage/types'
import { UPDATE_STATUS } from 'client/shared/js/services/status/types'
import userApi from './../api'

export const changeUsername = (userId, username) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_STATUS,
      status: {
        changeUsername: {
          isUpdating: true
        }
      }
    })

    return dispatch(checkAndRefreshUserToken())
      .then(() => {
        return userApi.updateUsername(userId, { username })
      })
      .then((res) => {
        const { username } = res.data

        dispatch({
          type: SUCCESS_MESSAGE,
          message: `Your username has been successfully updated.`,
          track: {
            event: {
              name: 'Username changed'
            }
          }
        })
        dispatch({
          type: UPDATE_USER_PROFILE,
          data: {
            username
          }
        })

        dispatch({
          type: UPDATE_STATUS,
          status: {
            changeUsername: {
              isUpdating: false
            }
          }
        })
      })
      .catch((error) => {
        const { data } = error.response

        if (data?.status >= 400 && data?.status < 500) {
          dispatch({
            type: UPDATE_STATUS,
            status: {
              changeUsername: {
                isUpdating: false,
                error: data.message
              }
            }
          })
        } else {
          dispatch({
            type: API_ERROR
          })
          dispatch({
            type: UPDATE_STATUS,
            status: {
              changeUsername: {
                isUpdating: false
              }
            }
          })
        }
      })
  }
}

export const updateProfile = (userId, profile) => {
  return (dispatch) => {
    return dispatch(checkAndRefreshUserToken())
      .then(() => {
        return userApi.updateProfile(userId, profile)
          .then((res) => {
            const result = res.data || {}

            dispatch({
              type: UPDATE_USER_PROFILE,
              data: {
                ...profile,
                ...result
              },
              track: {
                user: {
                  id: userId,
                  props: {
                    '$name': profile.name
                  }
                },
                event: {
                  name: 'User profile updated'
                }
              }
            })
            dispatch({
              type: SUCCESS_MESSAGE,
              message: 'Your profile has been successfully updated'
            })
          })
          .catch((err) => {
            dispatch({
              type: API_ERROR,
              message: 'We ran into an issue updating your profile.'
            })
          })
      })
  }
}

export const uploadProfilePhoto = (userId, filename, imageData) => {
  return (dispatch) => {
    return dispatch(checkAndRefreshUserToken())
      .then(() => {
        const data = {
          filename,
          data: imageData
        }
        return userApi.uploadProfilePhoto(userId, data)
          .then((res) => {
            const profile = res.data

            if (profile) {
              dispatch({
                type: UPDATE_USER_PROFILE,
                data: {
                  ...data,
                  ...profile
                },
                track: {
                  event: {
                    name: 'Upload profile photo'
                  }
                }
              })
            } else {
              dispatch({
                type: API_ERROR,
                message: 'We ran into an issue updating your profile photo.'
              })
            }
          })
          .catch((err) => {
            dispatch({
              type: API_ERROR,
              message: 'We ran into an issue updating your profile photo.'
            })
          })
      })
  }
}

export const deleteProfilePhoto = (userId) => {
  return (dispatch) => {
    return dispatch(checkAndRefreshUserToken())
      .then(() => {
        return userApi.deleteProfilePhoto(userId)
          .then((res) => {
            dispatch({
              type: UPDATE_USER_PROFILE,
              data: {
                photoUrl: ''
              },
              track: {
                event: {
                  name: 'Delete profile photo'
                }
              }
            })
            dispatch({
              type: SUCCESS_MESSAGE,
              message: 'Your profile photo has been successfully removed'
            })
          })
          .catch((err) => {
            dispatch({
              type: API_ERROR,
              message: 'We ran into an issue deleting your profile photo.'
            })
          })
      })
  }
}
