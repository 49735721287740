import { channel, buffers } from 'redux-saga'
import { all, call, fork, put, spawn, take } from 'redux-saga/effects'
import { logError } from 'client/shared/js/utils/browser'

const _respawnOnError = (saga) => function * () {
  while (true) {
    try {
      yield call(saga)
      break
    } catch (err) {
      logError('Saga Error', err)
    }
  }
}

/**
 * Spawns saga(s) in try block. On catch, log error and re-spawn them
 * https://github.com/redux-saga/redux-saga/issues/570#issuecomment-258038482
 * @param {GeneratorFunction|Array} sagas The saga or array of sagas
 */
export function * respawnOnError (sagas) {
  if (typeof sagas === 'function') {
    yield spawn(_respawnOnError(sagas))
  } else {
    yield all(sagas.map((saga) => spawn(_respawnOnError(saga))))
  }
}

/**
 * Forks the saga with a channel into which data is put. If the instance is
 * still running, skips the fork and puts data into the channel
 * @param {Object} instances The object ref kept by parent saga
 * @param {String} id The key for the instance
 * @param {GeneratorFunction} saga The saga
 * @param {Any} data The data to be put into the channel
 * @param {...Any} args Extra args to be passed to the saga
 */
export function * forkChanneledSaga (instances, id, saga, data, ...args) {
  if (!instances[id]) {
    const chan = channel(buffers.sliding(1))
    yield put(chan, data)

    yield fork(function * () {
      yield call(saga, chan, ...args)

      delete instances[id]
    })

    instances[id] = chan
  } else {
    yield put(instances[id], data)
  }
}

/**
 * Works the same as `takeLeading` except it discriminates among the same actions
 * when different id is given
 * https://redux-saga.js.org/docs/api/#takeleadingpattern-saga-args
 * https://github.com/redux-saga/redux-saga/pull/1744
 * @param {String} patternOrChannel The action pattern or channel
 * @param {Function} idSelector Callback for extracting id from the action
 * @param {GeneratorFunction} saga The saga action handler
 * @param {...any} params The params to pass into saga
 */
export const takeLeadingById = (patternOrChannel, saga, idSelector, ...params) => fork(function * () {
  const running = {}

  while (true) {
    const action = yield take(patternOrChannel)
    const id = idSelector(action)

    if (!running[id]) {
      running[id] = true

      yield fork(function * () {
        yield call(saga, ...params, action)
        delete running[id]
      })
    }
  }
})
