import * as TYPES from './types'
import * as schema from './schema'

export const updateStatus = (status) => ({
  type: TYPES.UPDATE_STATUS,
  status
})

export const updateUserStatus = (userId, username, status) => ({
  type: TYPES.UPDATE_USER_STATUS,
  data: schema.normalizeUserStatus(userId, username, status)
})

export const updatePlaceStatus = (gpid, status) => ({
  type: TYPES.UPDATE_PLACE_STATUS,
  data: schema.normalizePlaceStatus(gpid, status)
})

export const updateDestinationStatus = (gpid, status) => ({
  type: TYPES.UPDATE_DESTINATION_STATUS,
  data: {
    gpid,
    status
  }
})

export const updatePopularItemsStatus = (status) => ({
  type: TYPES.UPDATE_POPULAR_ITEMS_STATUS,
  data: {
    status
  }
})
