import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class Page extends Component {
  renderBackgroundImg = () => {
    const style = {
      backgroundImage: `url(${this.props.backgroundImage})`
    }

    const backgroundClass = classNames({
      'page-background': true,
      'jumbotron': true,
      'jumbotron-fluid': true,
      'center': this.props.center
    }, this.props.className)

    return (
      <div className={backgroundClass} style={style}>
        <div className='background-wrapper'>
          {this.props.children}
        </div>
      </div>
    )
  }

  renderPage = () => {
    const style = {
      'backgroundColor': this.props.backgroundColor
    }

    const pageClass = classNames({
      'page': true,
      'center': this.props.center
    }, this.props.className)

    return (
      <div className={pageClass} style={style}>
        {this.props.children}
      </div>
    )
  }

  render () {
    return (this.props.backgroundImage)
      ? this.renderBackgroundImg()
      : this.renderPage()
  }
}

Page.defaultProps = {
  className: '',
  backgroundColor: '#FFFFFF',
  center: false
}

Page.propTypes = {
  children: PropTypes.node,
  center: PropTypes.bool,
  className: PropTypes.string,
  backgroundImage: PropTypes.string,
  backgroundColor: PropTypes.string
}

export default Page
