import axios from 'axios'
import apiConfig from 'common/configs/api'
import { getHeaders } from 'client/shared/js/services/auth/api'

const { apiUrl, places } = apiConfig
const placesUrl = `${apiUrl}${places.url}`

export const getPlaceDetails = (placeId) => {
  const headers = getHeaders()
  const url = `${placesUrl}/${placeId}${places.details}`

  return axios.get(url, { headers })
}

export default {
  getPlaceDetails
}
