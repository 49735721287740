import { normalize } from 'normalizr'
import assignWith from 'lodash/assignWith'
import partialRight from 'lodash/partialRight'
import { board, idAttribute as boardIdAttribute } from 'client/shared/js/services/boards/schema'
import { list, idAttribute as listIdAttribute } from 'client/shared/js/services/lists/schema'

const customizer = (left, right) => left ?? right
const assignDefaults = partialRight(assignWith, customizer)

export const createList = {
  [boardIdAttribute]: board,
  [listIdAttribute]: list
}

/**
 * Normalizes createList action data. When ids are found both in argument and data,
 * the former takes precedence
 * @param {String} boardId The board id
 * @param {String} listId The list id
 * @param {Object} boardData The board data
 * @param {Object} listData The list data
 *
 * boardData = {
 *   bid
 * }
 *
 * listData = {
 *   lid
 * }
 *
 * output = {
 *   entities: {
 *     boards,
 *     lists
 *   },
 *   result: {
 *     bid,
 *     lid
 *   }
 * }
 */
export const normalizeCreateList = (boardId, listId, boardData, listData) => {
  return normalize({
    [boardIdAttribute]: assignDefaults({ [boardIdAttribute]: boardId }, boardData),
    [listIdAttribute]: assignDefaults({ [listIdAttribute]: listId }, listData)
  }, createList)
}
