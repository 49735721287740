import { normalizeBoardAttrs } from 'client/shared/js/services/boards/schema'

const entityKey = 'collaborators'

export const normalizeCollaborator = (boardId, collaboratorData) => {
  return normalizeBoardAttrs(boardId, { [entityKey]: [collaboratorData] })
}

export const normalizeCollaborators = (boardId, collaboratorsData) => {
  return normalizeBoardAttrs(boardId, { [entityKey]: collaboratorsData })
}
