import Loadable from 'client/shared/js/components/Loadable'
import RouteMap from 'common/configs/routeMap'

const boardRoutes = RouteMap.board
const path = RouteMap.public.url
const sharedBoardIdPath = `${path}/:sharedBoardId`

export const mapPath = `${sharedBoardIdPath}${boardRoutes.map}`
export const galleryPath = `${sharedBoardIdPath}${boardRoutes.gallery}/:imageId?`
export const boardDetailsPath = `${sharedBoardIdPath}${boardRoutes.public.url}/:boardSharedBoardId?`
export const boardPlaceDetailsPath = `${sharedBoardIdPath}${boardRoutes.public.url}/:boardSharedBoardId?${boardRoutes.public.listPlace}/:listPlaceId?`
export const listPlaceDetailsPath = `${sharedBoardIdPath}${boardRoutes.listPlace}/:listPlaceId?`
export const rootPath = `${sharedBoardIdPath}`
export const catchAllPath = `${sharedBoardIdPath}/*`

const PublicBoard = Loadable(() => import(/* webpackChunkName: "PublicBoard" */ '../PublicBoard'))

export const routes = () => {
  return [
    {
      path: [
        galleryPath,
        boardDetailsPath,
        boardPlaceDetailsPath,
        listPlaceDetailsPath,
        rootPath,
        catchAllPath
      ],
      exact: true,
      track: 'Public board',
      component: PublicBoard
    }
  ]
}

export default routes
