import nanoid from 'nanoid'
import isInteger from 'lodash/isInteger'
import isArray from 'lodash/isArray'
import * as listPlacesTYPES from './types'
import * as boardTYPES from '../boards/types'
import * as listsTYPES from '../lists/types'
import * as statusTYPES from 'client/shared/js/services/status/types'
import * as listsActions from '../lists/actions'
import * as authActions from 'client/shared/js/services/auth/actions'
import * as globalMessageActions from 'client/shared/js/services/globalMessage/actions'
import * as statusActions from 'client/shared/js/services/status/actions'
import * as listPlacesActionsSchema from './schema'
import * as listsSchema from 'client/shared/js/services/lists/schema'
import * as listPlacesSchema from 'client/shared/js/services/listPlaces/schema'
import * as statusSchema from 'client/shared/js/services/status/schema'
import * as ERRORS from 'client/shared/js/enums/errors'
import boardsApi from '../boards/api'
import { trackEvent } from 'client/shared/js/utils/tracking'
import { insertTagIntoTagOrder, removeTagFromTagOrder } from 'client/shared/js/utils/tags'
import { selectors } from 'client/app/js/reducer'

const TYPES = {
  ...boardTYPES,
  ...listPlacesTYPES,
  ...listsTYPES,
  ...statusTYPES
}

const actions = {
  ...listsActions,
  ...authActions,
  ...globalMessageActions,
  ...statusActions
}

const schema = {
  ...listPlacesActionsSchema,
  ...listsSchema,
  ...listPlacesSchema,
  ...statusSchema
}

export const beginAddPlace = (board, list, listPlaceData = {}, index) => {
  return (dispatch) => {
    const bid = board.bid
    const lid = list.lid
    const lpid = listPlaceData.lpid || nanoid()
    const placesOrder = (list.placesOrder) ? [...list.placesOrder] : []
    const listPlace = {
      ...listPlaceData,
      lid,
      lpid,
      // Set local timestamp, which will be updated laert
      meta: {
        createdDate: new Date().getTime(),
        updatedDate: new Date().getTime()
      }
    }
    const isValidIndex = isInteger(index)

    if (isValidIndex) {
      placesOrder.splice(index, 0, lpid)
    } else {
      placesOrder.push(lpid)
    }

    dispatch({
      type: TYPES.BEGIN_ADD_PLACE,
      track: {
        board: {
          name: 'Place Added',
          props: {
            lid,
            lpid
          },
          data: board
        }
      }
    })

    dispatch(commitAddPlace({
      lid,
      listPlace,
      placesOrder
    }))

    return dispatch(actions.checkAndRefreshUserToken())
      .then(() => {
        return boardsApi.createListPlace(bid, lid, lpid, listPlace)
          .catch((error) => {
            const code = error.response?.data?.type

            if (code === ERRORS.BOARD_NOT_FOUND) {
              let message = 'The board you were editing no longer exists.'

              dispatch(actions.apiError(message, 'modal'))
            } else {
              let message = 'Whoops, we couldn\'t add your new place.'

              dispatch(actions.apiError(message))
            }
          })
      })
  }
}

export const commitAddPlace = (addPlaceData) => ({
  type: TYPES.COMMIT_ADD_PLACE,
  data: schema.normalizeUpdatePlace(addPlaceData)
})

export const beginUpdatePlace = (board, list, listPlace, options = {}) => {
  return (dispatch) => {
    dispatch({
      type: TYPES.BEGIN_UPDATE_PLACE,
      options,
      data: {
        lid: list.lid,
        listPlace
      },
      track: {
        board: {
          name: 'Place Updated',
          props: {
            lid: list?.lid,
            lpid: listPlace?.lpid
          },
          data: board
        }
      }
    })
  }
}

export const commitUpdatePlace = (placeData) => ({
  type: TYPES.COMMIT_UPDATE_PLACE,
  data: schema.normalizeUpdatePlace(placeData)
})

export const collaboratorUpdatePlace = (placeData) => ({
  type: TYPES.COLLABORATOR_UPDATE_PLACE,
  data: schema.normalizeUpdatePlace(placeData)
})

export const preemptUpdatePlaceError = (lpid, placeData) => ({
  type: TYPES.PREEMPT_UPDATE_PLACE_ERROR,
  error: {
    type: ERRORS.PLACE_UPDATE_CONFLICT
  },
  data: schema.normalizeListPlaceStatus(lpid, {
    sent: placeData
  })
})

export const updatePlaceSuccess = (data) => ({
  type: TYPES.UPDATE_PLACE_SUCCESS,
  data: schema.normalizeUpdatePlace(data)
})

export const updatePlaceError = (lpid, error) => ({
  type: TYPES.UPDATE_PLACE_ERROR,
  data: schema.normalizeListPlaceAttrs({ lpid }),
  error
})

export const clearPlaceStatus = (lpid) => {
  return {
    type: TYPES.CLEAR_PLACE_STATUS,
    data: schema.normalizeListPlaceAttrs(lpid)
  }
}

export const deletePlace = (board, list, place, listPlaceIndex) => {
  return (dispatch, getState) => {
    trackEvent('Place Deleted')

    const boardId = board.bid
    const listId = list.lid
    const listPlaceId = place.lpid

    dispatch(commitDeletePlace(listId, listPlaceId))

    return dispatch(actions.checkAndRefreshUserToken())
      .then(() => {
        return boardsApi.deleteListPlace(boardId, listId, listPlaceId)
      })
      .catch((error) => {
        const code = error.response?.data?.type

        if (code === ERRORS.LIST_NOT_FOUND) {
          dispatch(actions.commitDeleteList(listId))

          let message = 'The list you were editing no longer exists.'
          dispatch(actions.apiError(message, 'modal'))
        } else if (code === ERRORS.BOARD_NOT_FOUND) {
          let message = 'The board you were editing no longer exists.'
          dispatch(actions.apiError(message, 'modal'))
        } else {
          let message = 'Whoops, we couldn\'t delete your place.'
          dispatch(actions.apiError(message))
        }
      })
  }
}

export const commitDeletePlace = (lid, lpid) => ({
  type: TYPES.COMMIT_DELETE_PLACE,
  data: schema.normalizeDeletePlace(lid, lpid)
})

export const beginReorderPlacesWithinList = (board, destination) => {
  return (dispatch) => {
    const boardId = board.bid
    const { lid, placesOrder } = destination

    dispatch(actions.checkAndRefreshUserToken())
      .then(() => {
        return boardsApi.updateListPlacesOrder(boardId, lid, { placesOrder })
      })
      .catch((error) => {
        let message = 'Whoops, we couldn\'t move your place.'

        dispatch(actions.apiError(message))
      })

    dispatch({
      type: TYPES.BEGIN_REORDER_PLACES_WITHIN_LIST,
      track: {
        board: {
          name: 'Place Reordered',
          props: {
            'Type': 'Within List',
            lid
          },
          board: board
        }
      }
    })

    dispatch(commitReorderPlacesWithinList({
      lid,
      placesOrder
    }))
  }
}

export const commitReorderPlacesWithinList = (listData) => ({
  type: TYPES.COMMIT_REORDER_PLACES_WITHIN_LIST,
  data: schema.normalizeList(listData)
})

/**
 * Moves list place(s) between two lists
 * @param {Object} board The board object
 * @param {Object|Array} listPlaces The listPlace object or array of listPlace objects
 * @param {Object} source The source list object of the move
 * @param {Object} destination The destination list object of the move
 * @param {Object} options The options to specify for this action
 */
export const beginReorderPlacesBetweenLists = (board, listPlaces, source, destination, options = {}) => {
  return async (dispatch) => {
    const boardId = board.bid
    const lpid = (isArray(listPlaces)) ? listPlaces.map(({ lpid }) => lpid) : listPlaces.lpid
    const data = {
      lpid,
      source,
      destination
    }

    dispatch({
      type: TYPES.BEGIN_REORDER_PLACES_BETWEEN_LISTS,
      track: {
        board: {
          name: 'Place Reordered',
          props: {
            'Type': 'Between Lists',
            sourceList: source?.lid,
            destinationList: destination?.lid,
            lpid
          },
          data: board
        }
      }
    })

    // Locally update lid's in listPlaces to destination lid
    const updatedListPlaces = (isArray(listPlaces)) ? (
      listPlaces.map((listPlace) => ({
        ...listPlace,
        lid: destination.lid
      }))
    ) : [{
      ...listPlaces,
      lid: destination.lid
    }]

    dispatch(commitReorderPlacesBetweenLists(boardId, {
      listPlaces: updatedListPlaces,
      source,
      destination
    }))

    try {
      await dispatch(actions.checkAndRefreshUserToken())

      const res = await boardsApi.reorderListPlaces(boardId, data, options)

      dispatch(reorderPlacesBetweenListsSuccess(boardId, res.data))
    } catch {
      let message = 'Whoops, we couldn\'t reorder your place(s) into the new list.'

      dispatch(actions.apiError(message))
    }
  }
}

export const commitReorderPlacesBetweenLists = (boardId, reorderPlacesData) => ({
  type: TYPES.COMMIT_REORDER_PLACES_BETWEEN_LISTS,
  data: schema.normalizeReorderPlacesBetweenLists(boardId, reorderPlacesData)
})

export const reorderPlacesBetweenListsSuccess = (boardId, reorderPlacesData) => ({
  type: TYPES.REORDER_PLACES_BETWEEN_LISTS_SUCCESS,
  data: schema.normalizeReorderPlacesBetweenLists(boardId, reorderPlacesData)
})

export const movePlacesToNewList = (board, listPlaces, sourceList, destinationList, index = 0) => {
  return async (dispatch) => {
    const boardId = board.bid
    const lpid = (isArray(listPlaces)) ? listPlaces.map(({ lpid }) => lpid) : listPlaces.lpid
    const listId = nanoid()
    const listOrder = [...board.listOrder]
    listOrder.splice(index, 0, listId)

    const source = { ...sourceList }
    const destination = {
      ...destinationList,
      bid: boardId,
      lid: listId
    }

    const data = {
      lpid,
      source,
      destination
    }

    dispatch({
      type: TYPES.BEGIN_REORDER_PLACES_BETWEEN_LISTS,
      track: {
        board: {
          name: 'Rename unorganized list to new list',
          props: {
            sourceList: source.lid,
            destinationList: destination.lid,
            lpid
          },
          data: board
        }
      }
    })

    // Locally update lid's in listPlaces to destination lid
    const updatedListPlaces = (isArray(listPlaces)) ? (
      listPlaces.map((listPlace) => ({
        ...listPlace,
        lid: destination.lid
      }))
    ) : [{
      ...listPlaces,
      lid: destination.lid
    }]

    dispatch(commitReorderPlacesBetweenLists(boardId, {
      listOrder,
      listPlaces: updatedListPlaces,
      source,
      destination
    }))

    const options = {
      createDestinationList: true,
      index
    }

    try {
      await dispatch(actions.checkAndRefreshUserToken())

      const res = await boardsApi.reorderListPlaces(boardId, data, options)

      dispatch(reorderPlacesBetweenListsSuccess(boardId, res.data))
    } catch {
      let message = 'Whoops, we couldn\'t reorder your place(s) into the new list.'

      dispatch(actions.apiError(message))
    }
  }
}

/**
 * Adds a tag against a list place
 * @param {String} boardId The board id
 * @param {String} listId The list id
 * @param {String} listPlaceId The list place id
 * @param {Object} tagName The tag name
 * @param {Object} index The tag order index to insert the tag
 */
export const addListPlaceTag = (bid, lid, lpid, tagName, index = 0) => {
  return (dispatch, getState) => {
    const listPlace = selectors.selectListPlace(getState(), lpid)
    const { tagOrder = [] } = listPlace
    const updatedTagOrder = insertTagIntoTagOrder(tagOrder, tagName, index)

    // Optimistically update UI
    dispatch({
      type: TYPES.ADD_LIST_PLACE_TAG,
      data: schema.normalizeListPlaceAttrs(lpid, {
        tagOrder: updatedTagOrder
      })
    })

    const tagData = {
      tagName,
      index
    }

    return dispatch(actions.checkAndRefreshUserToken())
      .then(() => boardsApi.addListPlaceTag(bid, lid, lpid, tagData))
      .then((res) => {
        dispatch({
          type: TYPES.ADD_LIST_PLACE_TAG,
          data: schema.normalizeUpdatePlace(res.data),
          track: {
            board: {
              name: 'Add list place tag',
              props: {
                lid,
                lpid,
                tagName
              }
            }
          }
        })
      })
      .catch((err) => {
        let message = 'We could not add that tag.'

        dispatch(actions.apiError(message))
      })
  }
}

/**
 * Deletes a tag from a list place
 * @param {String} bid The board id
 * @param {String} lid The list id
 * @param {String} lpid The list place id
 * @param {Object} tagName The tag name
 */
export const deleteListPlaceTag = (bid, lid, lpid, tagName) => {
  return (dispatch, getState) => {
    const listPlace = selectors.selectListPlace(getState(), lpid)
    const { tagOrder = [] } = listPlace
    const updatedTagOrder = removeTagFromTagOrder(tagOrder, tagName)

    // Optimistically delete tag
    dispatch({
      type: TYPES.DELETE_LIST_PLACE_TAG,
      data: schema.normalizeListPlaceAttrs(lpid, {
        tagOrder: updatedTagOrder
      })
    })

    const tagData = {
      tagName
    }

    return dispatch(actions.checkAndRefreshUserToken())
      .then(() => boardsApi.deleteListPlaceTag(bid, lid, lpid, tagData))
      .then((res) => {
        dispatch({
          type: TYPES.DELETE_LIST_PLACE_TAG,
          data: schema.normalizeUpdatePlace(res.data),
          track: {
            board: {
              name: 'Delete list place tag',
              props: {
                lid,
                lpid,
                tagName
              }
            }
          }
        })
      })
      .catch(() => {
        dispatch(actions.apiError('We could not delete that tag.'))
      })
  }
}
