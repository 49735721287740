import {
  TRACK_USER
} from '../types'
import { checkAndRefreshUserToken, reauthenticate } from '../../auth/actions'
import { removeToken } from '../../auth/api'
import { API_REQUEST, API_ERROR, AUTH_ERROR, SUCCESS_MESSAGE } from '../../globalMessage/types'
import userApi from '../api'
import RouteMap from 'common/configs/routeMap'
import {
  trackEvent,
  resetUser,
  deleteUser as deleteUserTracking
} from 'client/shared/js/utils/tracking'

// This assumes you already created the user through firebase SDK
export const newUserSignup = (userId, userData, redirect = RouteMap.home.url, type = 'password') => {
  return (dispatch) => {
    return dispatch(checkAndRefreshUserToken())
      .then(() => {
        const profile = userData.profile || {}

        if (profile.personalityType) {
          dispatch({
            type: TRACK_USER,
            track: {
              user: {
                id: userId,
                props: {
                  'Personality Type': profile.personalityType
                }
              }
            }
          })
        }

        return userApi.newUserSignup(userId, userData)
          .then((response) => {
            trackEvent('User Sign Up', {
              user: userId,
              signupMethod: type
            })

            if (redirect) {
              window.location = redirect
            } else {
              return response
            }
          })
          .catch((err) => {
            dispatch({
              type: API_ERROR,
              message: 'There seems to be an error creating your account.'
            })
          })
      })
  }
}

export const changePassword = (email, oldPassword, newPassword) => {
  return (dispatch) => {
    return dispatch(reauthenticate(email, oldPassword))
      .then((user) => {
        return user.updatePassword(newPassword)
      })
      .then(() => {
        dispatch({
          type: SUCCESS_MESSAGE,
          message: 'Your password has been successfully updated',
          track: {
            event: {
              name: 'Password reset'
            }
          }
        })
      })
      .catch((error) => {
        dispatch({
          type: AUTH_ERROR,
          error
        })
      })
  }
}

export const deleteAccount = (userId, email, password) => {
  return (dispatch) => {
    dispatch({
      type: API_REQUEST
    })

    return dispatch(reauthenticate(email, password))
      .then(dispatch(checkAndRefreshUserToken()))
      .then(() => {
        deleteUserTracking()
        resetUser()

        return userApi.deleteUser(userId)
          .then(() => {
            const accountRoutes = RouteMap.account
            const redirectUrl = `${accountRoutes.url}${accountRoutes.feedback}?email=${email}`

            removeToken()
            window.location = redirectUrl
          })
          .catch((err) => {
            dispatch({
              type: API_ERROR,
              message: 'We could not delete your account.'
            })
          })
      })
      .catch((error) => {
        dispatch({
          type: AUTH_ERROR,
          error
        })
      })
  }
}
