import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Page } from 'client/shared/js/components/Page'
import styles from './LeftRightPage.module.scss'

export const defaultProps = {}

export const propTypes = {
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  leftClassName: PropTypes.string,
  rightClassName: PropTypes.string,
  backgroundColor: PropTypes.string,
  header: PropTypes.node,
  left: PropTypes.element,
  right: PropTypes.element
}

const LeftRightPage = ({ className, wrapperClassName, leftClassName, rightClassName, backgroundColor, header, left, right }) => {
  return (
    <Page
      className={cx(styles['page'], className)}
      backgroundColor={backgroundColor} >
      {header}
      <div className={cx(styles['page-wrapper'], wrapperClassName)}>
        <div className={cx(styles['left-section'], leftClassName)}>
          {left}
        </div>
        <div className={cx(styles['right-section'], rightClassName)}>
          {right}
        </div>
      </div>
    </Page>
  )
}

LeftRightPage.defaultProps = defaultProps
LeftRightPage.propTypes = propTypes

export default LeftRightPage
