import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Button } from 'reactstrap'
import LoadButton from 'client/shared/js/components/LoadButton'
import { WarningModal, useModal } from 'client/app/js/components/Modal'
import { isPrivateBoard } from 'client/app/js/utils/accessInterpreter/board'
import { selectors } from 'client/app/js/reducer'
import styles from '../../index.module.scss'

export const propTypes = {
  board: PropTypes.object,
  cancelText: PropTypes.string,
  saveText: PropTypes.string,
  deleteText: PropTypes.string,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  validateForm: PropTypes.func
}

export const defaultProps = {
  cancelText: 'Cancel',
  saveText: 'Save Changes',
  deleteText: 'Delete board'
}

const Footer = (props) => {
  const status = useSelector(selectors.selectStatus)
  const [isModalOpen, toggleModal] = useModal('Board Delete Request')

  return (
    <FooterView
      {...props}
      status={status}
      isModalOpen={isModalOpen}
      toggleModal={toggleModal} />
  )
}

Footer.propTypes = propTypes
Footer.defaultProps = defaultProps

// ------------------------------------
// View components
// ------------------------------------

const viewPropTypes = {
  ...propTypes,
  isModalOpen: PropTypes.bool,
  toggleModal: PropTypes.func
}

const FooterView = (props) => {
  let DeleteBoardHandler = null

  if (props.onDelete) {
    const deleteMsg = (isPrivateBoard(props.board))
      ? 'This process cannot be undone.'
      : 'All of your followers will no longer be able to see this board. This process cannot be undone.'

    DeleteBoardHandler = (
      <>
        <DeleteBtn {...props} />
        <WarningModal
          isLoading={!!props.status.updateBoard}
          isModalOpen={props.isModalOpen}
          headerText='Are you sure?'
          onSubmit={props.onDelete}
          toggleModal={props.toggleModal}>
          {`Do you really want to delete this board? ${deleteMsg}`}
        </WarningModal>
      </>
    )
  }

  return (
    <>
      {DeleteBoardHandler}
      <div className={cx(styles['main-cta-container'])}>
        {props.onCancel && props.cancelText && (
          <Button
            color='info'
            onClick={props.onCancel}>
            {props.cancelText}
          </Button>
        )}
        <LoadButton
          className={cx(styles['save-btn'])}
          color='success'
          isLoading={!!props.status.updateBoard}
          disabled={!props.validateForm()}
          type='submit'>
          {props.saveText}
        </LoadButton>
      </div>
    </>
  )
}

FooterView.propTypes = viewPropTypes

const btnPropTypes = {
  ...viewPropTypes
}

const DeleteBtn = ({ onDelete, toggleModal, deleteText }) => (onDelete)
  ? (
    <Button
      color='danger'
      onClick={toggleModal}>
      {deleteText}
    </Button>
  )
  : null

DeleteBtn.propTypes = btnPropTypes

export default Footer
