import stream from 'getstream'

const appId = __GETSTREAM_APP_ID__
const apiKey = __GETSTREAM_API_KEY__
const client = stream.connect(apiKey, null, appId)

export const FeedGroups = {
  user: 'user',
  board: 'board',
  timeline: 'timeline',
  notification: 'notification',
  announcement: 'announcement'
}

export const notification = (uid, token) => {
  return client.feed(FeedGroups.notification, uid, token)
}
