import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { trackPage } from 'client/shared/js/utils/tracking'
import Page from '../Page'

class UIErrorPage extends Component {
  componentDidMount () {
    trackPage({
      'PageName': 'UI Rendering Error'
    })
  }

  render () {
    return (
      <Page className='error'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col'>
              <div className='row text-center'>
                <div className='col'>
                  <img className='error-illustration' src='/static/assets/img/illustrations/server-down.svg' />
                  <h1>{this.props.title}</h1>
                  <p>{this.props.message}</p>
                  <div>
                    <a href='/' className='btn btn-primary'>Take me home!</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Page>
    )
  }
}

UIErrorPage.defaultProps = {
  title: 'Whoops, something went wrong.',
  message: 'Looks like something broke on our end. That\'s our bad! Try refreshing the page or clicking the button below to go back to the home page.'
}

UIErrorPage.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string
}

export default UIErrorPage
