import React from 'react'
import PropTypes from 'prop-types'
import {
  FOLLOW_USER,
  FOLLOW_REQUEST,
  FOLLOW_APPROVED
} from 'client/shared/js/enums/getstream/verbs'
import { UserFollowButton } from '../../../FollowButton'
import ItemActivity from '../ItemActivity'
import { getUserFollower } from 'client/app/js/utils/accessInterpreter/user'

export const Verbs = {
  [FOLLOW_USER]: FOLLOW_USER,
  [FOLLOW_REQUEST]: FOLLOW_REQUEST,
  [FOLLOW_APPROVED]: FOLLOW_APPROVED
}

const FollowButton = (user, status, follower, userActions) => {
  return (follower) ? (
    <div className='d-flex follow-btn-container'>
      <UserFollowButton
        user={user}
        owner={follower.user}
        status={status}
        label='followers'
        type='followers'
        onFollowUser={userActions.followUser}
        onUnfollowUser={userActions.unfollowUser}
        onApproveFollowRequest={userActions.approveFollowRequest} />
    </div>
  ) : null
}

const FollowFeedItem = (props) => {
  const { activity, user, userActions, status } = props
  const { actor, verb } = activity
  const actorUid = actor.id
  const follower = getUserFollower(user, actorUid)

  let FollowAction = null
  let message = ''

  switch (verb) {
    case FOLLOW_USER:
      message = 'started following you'
      FollowAction = FollowButton(user, status, follower, userActions)
      break
    case FOLLOW_REQUEST:
      message = 'has requested to follow you'
      FollowAction = FollowButton(user, status, follower, userActions)
      break
    case FOLLOW_APPROVED:
      message = 'has approved your follow request!'
      break
    default:
  }

  return (
    <ItemActivity {...props} message={message}>
      {FollowAction}
    </ItemActivity>
  )
}

FollowFeedItem.propTypes = {
  user: PropTypes.object.isRequired,
  userActions: PropTypes.object.isRequired,
  status: PropTypes.object,
  activity: PropTypes.object.isRequired
}

export default FollowFeedItem
