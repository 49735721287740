import {
  FOLLOW_USER,
  UNFOLLOW_USER,
  APPROVE_USER_FOLLOW,
  DELETE_USER_FOLLOW
} from '../types'
import { checkAndRefreshUserToken } from '../../auth/actions'
import { API_ERROR } from '../../globalMessage/types'
import { UPDATE_STATUS } from 'client/shared/js/services/status/types'
import userApi from '../api'

const updateUserFollowStatus = (dispatch, userId, isRunning) => {
  dispatch({
    type: UPDATE_STATUS,
    status: {
      followRequest: {
        [userId]: isRunning
      }
    }
  })
}

export const followUser = (userId, influencerUserId) => {
  return (dispatch) => {
    updateUserFollowStatus(dispatch, influencerUserId, true)

    return dispatch(checkAndRefreshUserToken())
      .then(() => {
        return userApi.followUser(userId, influencerUserId)
      })
      .then((res) => {
        const followUser = res.data

        dispatch({
          type: FOLLOW_USER,
          data: followUser,
          track: {
            event: {
              name: 'Follow user',
              props: {
                'uid': userId,
                'followUser': influencerUserId,
                'Type': followUser.type
              }
            }
          }
        })
      })
      .catch((err) => {
        dispatch({
          type: API_ERROR,
          message: 'Our bad, you can\'t seem to follow this user.'
        })
      })
      .finally(() => {
        updateUserFollowStatus(dispatch, influencerUserId, false)
      })
  }
}

export const unfollowUser = (userId, influencerUserId) => {
  return (dispatch) => {
    updateUserFollowStatus(dispatch, influencerUserId, true)

    return dispatch(checkAndRefreshUserToken())
      .then(() => {
        return userApi.unfollowUser(userId, influencerUserId)
      })
      .then((res) => {
        const user = res.data

        dispatch({
          type: UNFOLLOW_USER,
          data: user,
          track: {
            event: {
              name: 'Unfollow user',
              props: {
                'uid': userId,
                'followUser': influencerUserId
              }
            }
          }
        })
      })
      .catch((err) => {
        dispatch({
          type: API_ERROR,
          message: 'Our bad, you can\'t seem to unfollow this user.'
        })
      })
      .finally(() => {
        updateUserFollowStatus(dispatch, influencerUserId, false)
      })
  }
}

export const approveFollowRequest = (userId, requestUid) => {
  return (dispatch) => {
    updateUserFollowStatus(dispatch, requestUid, true)

    return dispatch(checkAndRefreshUserToken())
      .then(() => {
        return userApi.approveFollowRequest(userId, requestUid)
      })
      .then((res) => {
        const followUser = res.data

        dispatch({
          type: APPROVE_USER_FOLLOW,
          data: followUser,
          track: {
            event: {
              name: 'Approve follow user request',
              props: {
                uid: userId,
                requestUser: requestUid
              }
            }
          }
        })
      })
      .catch((err) => {
        dispatch({
          type: API_ERROR,
          message: 'Our bad, we can\'t seem to confirm this user follow request.'
        })
      })
      .finally(() => {
        updateUserFollowStatus(dispatch, requestUid, false)
      })
  }
}

export const deleteUserFollow = (userId, requestUid, type) => {
  return (dispatch) => {
    updateUserFollowStatus(dispatch, requestUid, true)

    return dispatch(checkAndRefreshUserToken())
      .then(() => {
        return userApi.deleteUserFollow(userId, requestUid)
      })
      .then((res) => {
        const user = res.data

        dispatch({
          type: DELETE_USER_FOLLOW,
          data: user,
          track: {
            event: {
              name: `Delete follow ${type}`,
              props: {
                uid: userId,
                requestUser: requestUid
              }
            }
          }
        })
      })
      .catch((err) => {
        dispatch({
          type: API_ERROR,
          message: 'Our bad, we can\'t seem to delete this user follow.'
        })
      })
      .finally(() => {
        updateUserFollowStatus(dispatch, requestUid, false)
      })
  }
}
