import boardsApi from '../api'
import {
  FETCH_SUGGESTIONS_FOR_USER_AND_BOARD_SUCCESS,
  FETCH_SUGGESTIONS_FOR_USER_AND_BOARD_ERROR,
  FETCH_SEARCH_SUGGESTIONS_FOR_BOARD_SUCCESS,
  FETCH_SEARCH_SUGGESTIONS_FOR_BOARD_ERROR
} from '../types'
import { checkAndRefreshUserToken } from 'client/shared/js/services/auth/actions'
import { updateStatus } from 'client/shared/js/services/status/actions'
import * as schema from 'client/app/js/services/boards/schema'

const fetchSuggestionsForUserAndBoardSuccess = (boardId, boardsData) => ({
  type: FETCH_SUGGESTIONS_FOR_USER_AND_BOARD_SUCCESS,
  data: schema.normalizeSuggestionsForUserAndBoard(boardId, boardsData)
})

const fetchSuggestionsForUserAndBoardError = () => ({
  type: FETCH_SUGGESTIONS_FOR_USER_AND_BOARD_ERROR
})

const fetchSearchSuggestionsForBoardSuccess = (boardId, boardsData) => ({
  type: FETCH_SEARCH_SUGGESTIONS_FOR_BOARD_SUCCESS,
  data: schema.normalizeSearchSuggestionsForBoard(boardId, boardsData)
})

const fetchSearchSuggestionsForBoardError = () => ({
  type: FETCH_SEARCH_SUGGESTIONS_FOR_BOARD_ERROR
})

/**
 * Calls the suggestions API to retrieve all recommendations for the provided user and board
 * @param {String} board The board object
 * @param {String} searchStr The search query string
 */
export const fetchSuggestionsForUserAndBoard = (board = {}) => {
  return (dispatch, getState) => {
    // Prevents multiple triggers
    if (getState().status?.isFetchingBoardSuggestions) {
      return
    }

    const boardId = board?.bid
    dispatch(updateStatus({ isFetchingBoardSuggestions: true }))

    return dispatch(checkAndRefreshUserToken())
      .then(() => {
        // TODO: Check if this is the best way to check for 'guest'
        return (boardId === 'guest')
          ? boardsApi.searchSuggestionsByLocation({ location: board?.location ?? {} })
          : boardsApi.fetchSuggestionsForUserAndBoard(boardId)
      })
      .then((response) => {
        const { data } = response
        dispatch(fetchSuggestionsForUserAndBoardSuccess(boardId, data))
      })
      .catch(() => {
        dispatch(fetchSuggestionsForUserAndBoardError())
      })
      .then(() => {
        dispatch(updateStatus({ isFetchingBoardSuggestions: false }))
      })
  }
}

/**
 * Calls the search suggestions API to retrieve place suggestions for the provided board id
 * @param {String} boardId The board object
 * @param {String} searchStr The search query string
 */
export const searchSuggestionsForBoard = (board = {}, searchStr) => {
  return (dispatch) => {
    const boardId = board?.bid

    if (searchStr) {
      dispatch(updateStatus({ isSearchingSuggestionsForBoard: true }))

      return dispatch(checkAndRefreshUserToken())
        .then(() => {
          return boardsApi.searchSuggestionsForBoard(boardId, { query: searchStr })
        })
        .then((response) => {
          const { data } = response
          dispatch(fetchSearchSuggestionsForBoardSuccess(boardId, data))
        })
        .catch(() => {
          dispatch(fetchSearchSuggestionsForBoardError())
        })
        .then(() => {
          dispatch(updateStatus({ isSearchingSuggestionsForBoard: false }))
        })
    } else {
      // Reset search
      dispatch(fetchSearchSuggestionsForBoardSuccess(boardId, {}))
    }
  }
}
