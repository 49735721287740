import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { convertTimezoneFromNow } from 'client/app/js/utils/date'
import Link from 'client/shared/js/components/Link'
import ProfilePhoto from 'client/shared/js/components/ProfilePhoto'
import UsernameTag from 'client/shared/js/components/UsernameTag'
import styles from './ItemActivity.module.scss'

const createLinkProps = (username, source, onClick) => {
  const trackingSource = (source) ? `Feed Item - ${source}` : 'Feed Item'

  return (username) ? {
    to: `/${username}`,
    trackProps: { 'source': trackingSource },
    onClick
  } : null
}

const ItemActivity = (props) => {
  const deleteFeedItem = () => {
    if (props.onDelete) {
      props.onDelete(id)
    } else {
      props.userActions.removeNotificationActivity(props.user.uid, id)
    }
  }

  const { id, actor, time, verb } = props.activity
  const { name = 'A user', photoUrl, username } = actor.data || {}
  const trackingSource = props.tracking || verb.toLowerCase()
  const linkProps = createLinkProps(username, trackingSource, props.onNavigation)

  const DefaultNameLabel = (actor.error === 'ReferenceNotFound') ? (<em>**Deleted user**</em>) : (<b>{name}</b>)
  const UserPhoto = (<ProfilePhoto photoUrl={photoUrl} title={name} />)
  const UserLink = (linkProps) ? (
    <>
      <Link {...linkProps}>
        <UsernameTag username={username} bold />
      </Link>
      <span className='activity-message'>{props.message}</span>
    </>
  ) : (
    <>
      <span className='mr-1'>{DefaultNameLabel}</span>
      <span className='activity-message align-text-bottom'>{props.message}</span>
    </>
  )

  return (
    <>
      <div className='d-flex align-items-center justify-content-between w-100'>
        <div className={cx(styles['item-activity-left'])}>
          {linkProps ? (
            <Link {...linkProps}>
              {UserPhoto}
            </Link>
          ) : UserPhoto}
          <div className='user-details'>
            <h5 className='activity-message-wrapper'>
              {UserLink}
            </h5>
            <h6 className='mb-0'>{convertTimezoneFromNow(time)}</h6>
          </div>
        </div>
        {props.children}
      </div>
      <UncontrolledDropdown>
        <DropdownToggle color='link' className='dark'>
          <FontAwesomeIcon icon={['fal', 'ellipsis']} />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={deleteFeedItem} className='delete-btn'>Delete notification</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  )
}

ItemActivity.propTypes = {
  children: PropTypes.node,
  activity: PropTypes.object,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  tracking: PropTypes.string,
  user: PropTypes.object,
  userActions: PropTypes.object,
  onNavigation: PropTypes.func,
  onDelete: PropTypes.func
}

ItemActivity.defaultProps = {
  user: {},
  userActions: {},
  message: '',
  onNavigation: () => false
}

export default ItemActivity
