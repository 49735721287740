import { schema, normalize } from 'normalizr'
import { transformPlacePhotoUrls } from 'client/shared/js/utils/photo'

export const entityKey = 'places'
export const idAttribute = 'gpid'

export const place = new schema.Entity(entityKey, {}, {
  idAttribute,
  processStrategy: (entity, parent) => {
    const updatedEntity = { ...entity }

    if (entity.photos) {
      updatedEntity.photos = entity.photos.map((photo) => transformPlacePhotoUrls(photo))
    }

    if (entity.photo) {
      updatedEntity.photo = transformPlacePhotoUrls(entity.photo)
    }

    return updatedEntity
  }
})

/**
 * Normalizes place data
 * @param {String} gpid The Google place id
 * @param {Object} placeData The place data
 *
 * placeData = {
 *   gpid
 * }
 *
 * output = {
 *   entities: {
 *     places
 *   },
 *   result: {
 *     gpid
 *   }
 * }
 */
export const normalizePlace = (gpid = null, placeData) => {
  if (gpid) placeData.gpid = gpid

  return normalize(placeData, place)
}
