import { call, put, putResolve, takeLeading } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import boardsApi from '../api'
import * as boardActions from '../actions'
import * as authActions from 'client/shared/js/services/auth/actions'
import * as globalMessageActions from 'client/shared/js/services/globalMessage/actions'
import * as statusActions from 'client/shared/js/services/status/actions'
import * as boardsTYPES from '../types'
import { respawnOnError, takeLeadingById } from 'client/app/js/utils/saga'
import { getBoardRoute } from 'common/configs/routeMap'

const actions = {
  ...boardActions,
  ...authActions,
  ...globalMessageActions,
  ...statusActions
}

const TYPES = {
  ...boardsTYPES
}

// ======================================================
// Workers
// ======================================================

function * fetchBoards () {
  yield put(actions.updateStatus({ fetchBoards: true }))
  yield putResolve(actions.checkAndRefreshUserToken())

  try {
    const res = yield call(boardsApi.getBoards)
    if (res.data) {
      yield put(actions.fetchBoardsSuccess(res.data))
    } else {
      yield put(actions.apiError('Our bad, we can\'t seem to get your boards.'))
    }
  } catch (err) {
    yield put(actions.fetchBoardsError())
    yield put(actions.apiError('Our bad, we can\'t seem to get your boards.'))
  } finally {
    yield put(actions.updateStatus({ fetchBoards: false }))
  }
}

function * fetchPublicBoardDetails (action) {
  yield putResolve(actions.checkAndRefreshUserToken())

  try {
    const res = yield call(boardsApi.getPublicBoard, action.data, { redirect: false })
    if (res.data) {
      yield put(actions.fetchPublicBoardDetailsSuccess(res.data))
    } else {
      yield put(actions.apiError('Our bad, we can\'t seem to get your boards.'))
    }
  } catch (err) {
    yield put(actions.apiError('Our bad, we can\'t seem to get your boards.'))
  }
}

function * createBoard (action) {
  yield put(actions.updateStatus({ updateBoard: true }))
  yield putResolve(actions.checkAndRefreshUserToken())

  try {
    const res = yield call(boardsApi.createBoard, action.data)
    const board = res.data
    const boardId = board.bid

    yield put(actions.createBoardSuccess(board))
    yield put(push(getBoardRoute({ boardId }), true))
  } catch (err) {
    yield put(actions.apiError('Our bad, we can\'t seem to create this board.'))
  } finally {
    yield put(actions.updateStatus({ updateBoard: false }))
  }
}

// ======================================================
// Watchers
// ======================================================

function * watchFetchBoards () {
  yield takeLeading(TYPES.BEGIN_FETCH_BOARDS, fetchBoards)
}

function * watchFetchBoardDetails () {
  yield takeLeadingById(TYPES.BEGIN_FETCH_PUBLIC_BOARD_DETAILS, fetchPublicBoardDetails, ({ data }) => data)
}

function * watchCreateBoard () {
  yield takeLeading(TYPES.BEGIN_CREATE_BOARD, createBoard)
}

function * boardsSaga () {
  yield call(respawnOnError, [
    watchFetchBoards,
    watchFetchBoardDetails,
    watchCreateBoard
  ])
}

export default boardsSaga
