import {
  FOLLOW_BOARD,
  UNFOLLOW_BOARD,
  DELETE_BOARD_FOLLOW
} from '../types'
import * as authActions from 'client/shared/js/services/auth/actions'
import * as globalMessageActions from 'client/shared/js/services/globalMessage/actions'
import * as statusActions from 'client/shared/js/services/status/actions'
import boardsApi from '../api'

const actions = {
  ...authActions,
  ...globalMessageActions,
  ...statusActions
}

const updateFollowStatus = (dispatch, id, isRunning) => {
  dispatch(actions.updateStatus({
    followRequest: {
      [id]: isRunning
    }
  }))
}

export const followBoard = (boardId) => {
  return (dispatch) => {
    updateFollowStatus(dispatch, boardId, true)

    return dispatch(actions.checkAndRefreshUserToken())
      .then(() => {
        return boardsApi.followBoard(boardId)
      })
      .then((res) => {
        dispatch({
          type: FOLLOW_BOARD,
          data: res.data,
          track: {
            board: {
              name: 'Follow board',
              data: {
                'bid': boardId
              }
            }
          }
        })
      })
      .catch((err) => {
        const { data = {} } = err.response ?? {}
        let message = 'Our bad, you can\'t seem to follow this board.'

        if (data.status >= 400 && data.status < 500) {
          message = data.message
        }

        dispatch(actions.errorMessage(message))
      })
      .finally(() => {
        updateFollowStatus(dispatch, boardId, false)
      })
  }
}

export const unfollowBoard = (boardId) => {
  return (dispatch) => {
    updateFollowStatus(dispatch, boardId, true)

    return dispatch(actions.checkAndRefreshUserToken())
      .then(() => {
        return boardsApi.unfollowBoard(boardId)
      })
      .then((res) => {
        dispatch({
          type: UNFOLLOW_BOARD,
          data: res.data,
          track: {
            board: {
              name: 'Unfollow board',
              data: {
                'bid': boardId
              }
            }
          }
        })
      })
      .catch((err) => {
        dispatch(globalMessageActions.apiError('Our bad, you can\'t seem to unfollow this board.'))
      })
      .finally(() => {
        updateFollowStatus(dispatch, boardId, false)
      })
  }
}

export const deleteBoardFollow = (boardId, followerUid) => {
  return (dispatch) => {
    updateFollowStatus(dispatch, followerUid, true)

    return dispatch(actions.checkAndRefreshUserToken())
      .then(() => {
        return boardsApi.deleteBoardFollow(boardId, followerUid)
      })
      .then((res) => {
        dispatch({
          type: DELETE_BOARD_FOLLOW,
          data: res.data,
          track: {
            board: {
              name: 'Board follower deleted',
              props: {
                'Removed follower uid': followerUid
              },
              data: {
                bid: boardId
              }
            }
          }
        })
      })
      .catch((err) => {
        dispatch(actions.apiError('Our bad, we can\'t seem to delete this board follow.'))
      })
      .finally(() => {
        updateFollowStatus(dispatch, followerUid, false)
      })
  }
}
