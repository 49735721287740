import { schema, normalize } from 'normalizr'
import * as users from '../users/schema'
import * as boards from '../boards/schema'
import * as lists from '../lists/schema'
import * as listPlaces from '../listPlaces/schema'
import * as places from '../places/schema'

export const userStatus = new schema.Entity('userStatus', {}, { idAttribute: users.idAttribute })
export const boardStatus = new schema.Entity('boardStatus', {}, { idAttribute: boards.idAttribute })
export const listStatus = new schema.Entity('listStatus', {}, { idAttribute: lists.idAttribute })
export const listPlaceStatus = new schema.Entity('listPlaceStatus', {}, { idAttribute: listPlaces.idAttribute })
export const placeStatus = new schema.Entity('placeStatus', {}, { idAttribute: places.idAttribute })

export const normalizeUserStatus = (userId, usernameId, statusData) => {
  return normalize({
    [users.idAttribute]: userId,
    usernameId,
    ...statusData
  }, userStatus)
}

export const normalizeListStatus = (listId, statusData) => {
  return normalize({
    [lists.idAttribute]: listId,
    ...statusData
  }, listStatus)
}

export const normalizeListPlaceStatus = (listPlaceId, statusData) => {
  return normalize({
    [listPlaces.idAttribute]: listPlaceId,
    ...statusData
  }, listPlaceStatus)
}

export const normalizePlaceStatus = (gpid, statusData) => {
  return normalize({
    [places.idAttribute]: gpid,
    ...statusData
  }, placeStatus)
}
