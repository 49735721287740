/**
 * Returns true if next.meta.updatedDate is newer than prev
 * @param {Number|Object} prev The updatedDate OR object with meta.updatedDate
 * @param {Number|Object} next The updatedDate OR object with meta.updatedDate
 */
export const isNewerVersion = (prev, next) => {
  const prevVersion = (typeof prev === 'number') ? prev : prev?.meta?.updatedDate
  const nextVersion = (typeof next === 'number') ? next : next?.meta?.updatedDate

  return ((!prevVersion && nextVersion) || prevVersion < nextVersion)
}

/**
 * Returns true if next.meta.updatedDate is newer than or the same as prev
 * @param {Number|Object} prev The updatedDate OR object with meta.updatedDate
 * @param {Number|Object} next The updatedDate OR object with meta.updatedDate
 */
export const isNewerOrSameVersion = (prev, next) => {
  const prevVersion = (typeof prev === 'number') ? prev : prev?.meta?.updatedDate
  const nextVersion = (typeof next === 'number') ? next : next?.meta?.updatedDate

  return ((!prevVersion && nextVersion) || prevVersion <= nextVersion)
}
