import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { trackPage } from 'client/shared/js/utils/tracking'
import Page from '../Page'

class APIErrorPage extends Component {
  componentDidMount () {
    trackPage({
      'PageName': 'API Error'
    })
  }

  render () {
    return (
      <Page className='error'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col'>
              <div className='row text-center'>
                <div className='col'>
                  <img className='error-illustration' src='/static/assets/img/illustrations/not-found.svg' />
                  <h1>{this.props.title}</h1>
                  <p>{this.props.message} Click <a href='/'>here</a> or the button below to go back home.</p>
                  <div>
                    <a href='/' className='btn btn-primary'>Take me back!</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Page>
    )
  }
}

APIErrorPage.defaultProps = {
  title: 'API Error',
  message: 'Whoops, you were not supposed to access the page this way.'
}

APIErrorPage.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string
}

export default APIErrorPage
