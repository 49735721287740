import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import CloseButton from './CloseButton'
import LoadButton from 'client/shared/js/components/LoadButton'

const WarningModal = (props) => (
  <Modal
    isOpen={props.isModalOpen}
    toggle={props.toggleModal}
    size='lg'
    centered>
    <ModalHeader
      toggle={props.toggleModal}
      close={<CloseButton onClick={props.toggleModal} />}>
      {props.headerText}
    </ModalHeader>
    <ModalBody>
      {props.children}
    </ModalBody>
    <ModalFooter>
      <Button
        color='info'
        onClick={props.toggleModal}>
        Cancel
      </Button>
      <LoadButton
        color='danger'
        isLoading={!!props.isLoading}
        onClick={props.onSubmit}>
        {props.submitText}
      </LoadButton>
    </ModalFooter>
  </Modal>
)

WarningModal.propTypes = {
  isLoading: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  headerText: PropTypes.string,
  submitText: PropTypes.string,
  onSubmit: PropTypes.func,
  toggleModal: PropTypes.func,
  children: PropTypes.node.isRequired
}

WarningModal.defaultProps = {
  isLoading: false,
  headerText: '',
  submitText: 'Delete'
}

export default WarningModal
