import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'
import AuthWrapper from 'client/shared/js/components/AuthWrapper'
import { trackPage } from '../../utils/tracking'

// Recursively renders subroutes
// WARNING: Using this requires all the routes to already be defined on new page load, otherwise a 404 will occur
// Use at your own discretion
const SubrouteComponent = (props) => {
  const { rootUrl, path, exact, routes = [] } = props
  const matchPath = `${rootUrl}${path}`
  const subroutes = routes.map((routeConfig, index) => {
    return <SubrouteComponent {...routeConfig} key={`${path}_${index}`} rootUrl={matchPath} />
  })

  return (
    <Route
      path={matchPath}
      exact={exact}
      render={props => (
        <props.component {...props}>
          {subroutes}
        </props.component>
      )} />
  )
}

SubrouteComponent.propTypes = {
  rootUrl: PropTypes.string,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  routes: PropTypes.array,
  exact: PropTypes.bool
}

// Manages a route from the react-router route config
class RouteWithSubroutes extends Component {
  // Scroll top restoration on route change
  componentDidMount () {
    scroll.scrollToTop({
      duration: 0
    })

    if (this.props.track) {
      trackPage({
        'PageName': this.props.track,
        'Location': window.location.pathname
      })
    }
  }

  render () {
    const { path, routes = [], exact, sync, isPrivate } = this.props

    const subroutes = routes.map((routeConfig, index) => {
      return <SubrouteComponent {...routeConfig} key={`${path}_${index}`} rootUrl={path} />
    })

    // Sets up the AuthWrapper props to determine whether to run the validAuth check.
    // This requires the `sync` property to be provided in the route config
    const authProps = {
      user: this.props.user,
      onValidAuth: (sync) ? this.props.onValidAuth : null,
      forceAuthRedirect: true
    }

    return (
      <Route
        path={path}
        exact={exact}
        render={props => {
          return (isPrivate) ? (
            <AuthWrapper {...authProps}>
              <this.props.component {...props}>
                {subroutes}
              </this.props.component>
            </AuthWrapper>
          ) : (
            <this.props.component user={this.props.user} {...props}>
              {subroutes}
            </this.props.component>
          )
        }} />
    )
  }
}

RouteWithSubroutes.defaultProps = {
  isPrivate: false
}

RouteWithSubroutes.propTypes = {
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  location: PropTypes.object,
  track: PropTypes.string,
  routes: PropTypes.array,
  exact: PropTypes.bool,
  sync: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  user: PropTypes.object,
  onValidAuth: PropTypes.func,
  isPrivate: PropTypes.bool
}

export default RouteWithSubroutes
