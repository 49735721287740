import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Popover,
  PopoverBody
} from 'reactstrap'
import RouteMap from 'common/configs/routeMap'
import { trackEvent } from 'client/shared/js/utils/tracking'
import { logError } from 'client/shared/js/utils/browser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { notification } from 'client/shared/js/utils/getstream'
import NotificationFeed from '../NotificationFeed'
import NotificationContext from '../../context'
import './NotificationDropdown.scss'

class NotificationDropdown extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    userActions: PropTypes.object,
    status: PropTypes.object,
    token: PropTypes.string,
    navigate: PropTypes.func
  }

  static defaultProps = {
    user: {},
    status: {}
  }

  static contextType = NotificationContext

  state = {
    isPopoverOpen: false
  }

  componentDidMount () {
    // Subscribe to notification changes
    if (this.props.token) {
      const notificationFeed = notification(this.props.user.uid, this.props.token)
      notificationFeed.subscribe(this.context.setIncomingNotifications)
        .then(null, (err) => logError('Notification Feed Error', err))
    }

    this.props.userActions.fetchNotificationFeed(this.props.user.uid)
  }

  handleSeeAllClick = () => {
    trackEvent('See all notifications click')

    this.setState({
      isPopoverOpen: false
    })
    this.props.navigate(RouteMap.notifications.url)
  }

  toggleNotificationsPopover = () => {
    const updatedPopoverState = !this.state.isPopoverOpen

    if (updatedPopoverState) {
      trackEvent(`Show notifications`)
    }

    this.setState({
      isPopoverOpen: updatedPopoverState
    })
  }

  renderUnreadNotificationsIcon = () => {
    const notificationFeed = this.props.user.feeds.notification
    const unreadFeedCount = notificationFeed.unread || 0
    const unreadCount = unreadFeedCount + this.context.incomingNotifications.length

    // Don't show unread count when the popover is open
    return (unreadCount > 0 && !this.state.isPopoverOpen) ? (
      <div className='unread-count-badge'>
        {unreadCount}
      </div>
    ) : null
  }

  render () {
    return (this.props.user.uid) ? (
      <div className='notification-dropdown-container'>
        <Button id='notificationsBtn' color='info' className='notification-btn muted round' onClick={this.toggleNotificationsPopover}>
          <FontAwesomeIcon icon={['fas', 'bell']} className='font-inherit' />
        </Button>
        {this.renderUnreadNotificationsIcon()}
        <Popover
          className='notification-popover'
          placement='bottom'
          trigger='legacy'
          target='notificationsBtn'
          fade={false}
          toggle={this.toggleNotificationsPopover}
          isOpen={this.state.isPopoverOpen}>
          <PopoverBody>
            <h4 className='notification-header'>Notifications</h4>
            <NotificationFeed
              user={this.props.user}
              status={this.props.status}
              userActions={this.props.userActions}
              onNavigation={this.toggleNotificationsPopover}
              disableInfiniteScroll />
            <Button
              color='link'
              className='see-all-btn square accent'
              onClick={this.handleSeeAllClick}
              block>
              See all
            </Button>
          </PopoverBody>
        </Popover>
      </div>
    ) : null
  }
}

export default NotificationDropdown
