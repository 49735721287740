import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FormGroup, Input, Label } from 'reactstrap'
import CharacterCountWrapper from 'client/shared/js/components/CharacterCountWrapper'
import styles from '../../index.module.scss'

export const propTypes = {
  mountId: PropTypes.string,
  title: PropTypes.string,
  onTitleChange: PropTypes.func
}

export const defaultProps = {}

const TitleFormGroup = ({ mountId, title, onTitleChange }) => (
  <FormGroup className={cx(styles['form-group'])}>
    <Label
      className={cx(styles['label'])}
      for={`boardTitleInput-${mountId}`}>
      Name your board
    </Label>
    <CharacterCountWrapper current={title.length} max={50}>
      <Input
        id={`boardTitleInput-${mountId}`}
        placeholder='PDX Trip for Kicks'
        className='board-name-input'
        value={title}
        onChange={onTitleChange}
        maxLength='50' />
    </CharacterCountWrapper>
  </FormGroup>
)

TitleFormGroup.propTypes = propTypes
TitleFormGroup.defaultProps = defaultProps

export default TitleFormGroup
