/**
 * HipHerd api server error types
 */
export const BOARD_NOT_FOUND = 'board/not-found'
export const BOARD_UPDATE_CONFLICT = 'board/update-conflict'
export const BOARD_NOTES_UPDATE_CONFLICT = 'board/notes/update-conflict'
export const LIST_NOT_FOUND = 'list/not-found'
export const LIST_UPDATE_CONFLICT = 'list/update-conflict'
export const PLACE_NOT_FOUND = 'place/not-found'
export const PLACE_UPDATE_CONFLICT = 'place/update-conflict'

export default {
  BOARD_UPDATE_CONFLICT,
  BOARD_NOT_FOUND,
  BOARD_NOTES_UPDATE_CONFLICT,
  LIST_NOT_FOUND,
  LIST_UPDATE_CONFLICT,
  PLACE_NOT_FOUND,
  PLACE_UPDATE_CONFLICT
}
