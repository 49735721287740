import { call, put, putResolve } from 'redux-saga/effects'
import moment from 'moment'
import placesApi from './api'
import * as placedActions from './actions'
import * as authActions from 'client/shared/js/services/auth/actions'
import * as globalMessageActions from 'client/shared/js/services/globalMessage/actions'
import * as statusActions from 'client/shared/js/services/status/actions'
import * as placesTYPES from './types'
import { respawnOnError, takeLeadingById } from 'client/app/js/utils/saga'

const actions = {
  ...placedActions,
  ...authActions,
  ...globalMessageActions,
  ...statusActions
}

const TYPES = {
  ...placesTYPES
}

// ======================================================
// Workers
// ======================================================

function * fetchPlaceDetails (action) {
  const gpid = action.data
  yield put(actions.updatePlaceStatus(gpid, { isFetching: true }))
  yield putResolve(actions.checkAndRefreshUserToken())

  try {
    const res = yield call(placesApi.getPlaceDetails, gpid, { redirect: false })
    if (res.data) {
      yield put(actions.fetchPlaceDetailsSuccess(gpid, res.data))
      yield put(actions.updatePlaceStatus(gpid, { fetchedDate: moment().format() }))
    } else {
      yield put(actions.apiError('Our bad, we can\'t seem to get your place data.'))
    }
  } catch (err) {
    yield put(actions.apiError('Our bad, we can\'t seem to get your place data.'))
  } finally {
    yield put(actions.updatePlaceStatus(gpid, { isFetching: false }))
  }
}

// ======================================================
// Watchers
// ======================================================

function * watchFetchPlaceDetails () {
  yield takeLeadingById(TYPES.BEGIN_FETCH_PLACE_DETAILS, fetchPlaceDetails, ({ data }) => data)
}

function * placesSaga () {
  yield call(respawnOnError, [
    watchFetchPlaceDetails
  ])
}

export default placesSaga
