import { schema, normalize } from 'normalizr'
import { idAttribute as listIdAttribute } from '../lists/schema'
import { transformPlacePhotoUrls } from 'client/shared/js/utils/photo'

export const entityKey = 'listPlaces'
export const idAttribute = 'lpid'

export const listPlace = new schema.Entity(entityKey, {}, {
  idAttribute,
  processStrategy: (entity, parent) => {
    const updatedEntity = { ...entity }

    if (parent[listIdAttribute]) {
      updatedEntity[listIdAttribute] = parent[listIdAttribute]
    }

    if (entity.photo) {
      updatedEntity.photo = transformPlacePhotoUrls(entity.photo)
    }

    return updatedEntity
  }
})

/**
 * Normalizes listPlace data
 * @param {Object} listPlaceData The listPlace data
 *
 * listPlaceData = {
 *   lpid
 * }
 *
 * output = {
 *   entities: {
 *     listPlaces
 *   },
 *   result: {
 *     lpid
 *   }
 * }
 */
export const normalizeListPlace = (listPlaceData) => {
  return normalize({ [idAttribute]: listPlaceData }, { [idAttribute]: listPlace })
}

/**
 * Normalizes listPlace attributes
 * @param {String} listPlaceId The listPlace id
 * @param {*} listPlaceAttrs The listPlace attributes
 *
 * listPlaceAttrs = {
 *   ...any
 * }
 *
 * output = {
 *   entities: {
 *     listPlaces: {
 *       [lpid]: {
 *         ...any
 *       }
 *     }
 *   },
 *   result: {
 *     lpid
 *   }
 * }
 */
export const normalizeListPlaceAttrs = (listPlaceId, listPlaceAttrs = {}) => {
  return normalizeListPlace({
    [idAttribute]: listPlaceId,
    ...listPlaceAttrs
  })
}
