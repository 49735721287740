import { call, put, putResolve } from 'redux-saga/effects'
import moment from 'moment'
import usernameApi from 'client/app/js/services/usernames/api'
import * as usersActions from './actions'
import * as authActions from 'client/shared/js/services/auth/actions'
import * as globalMessageActions from 'client/shared/js/services/globalMessage/actions'
import * as statusActions from 'client/shared/js/services/status/actions'
import * as usersTYPES from './types'
import { respawnOnError, takeLeadingById } from 'client/app/js/utils/saga'

const actions = {
  ...usersActions,
  ...authActions,
  ...globalMessageActions,
  ...statusActions
}

const TYPES = {
  ...usersTYPES
}

// ======================================================
// Workers
// ======================================================

// FIXME: Merge this with `fetchUserFromUsername` action when we normalize all user data
function * fetchUser (action) {
  const { uid, username, options } = action.data

  yield put(actions.updateUserStatus(uid, username, { isFetching: true }))
  yield putResolve(actions.checkAndRefreshUserToken())

  try {
    const res = yield call(usernameApi.getUserFromUsername, username, options)

    if (res.data) {
      yield put(actions.fetchUserSuccess(res.data))
      yield put(actions.updateUserStatus(uid, username, { fetchedDate: moment().format() }))
    } else {
      yield put(actions.apiError('Our bad, we can\'t seem to get user info.'))
    }
  } catch (err) {
    yield put(actions.fetchUserError())
    yield put(actions.apiError('Our bad, we can\'t seem to get user info.'))
  } finally {
    yield put(actions.updateUserStatus(uid, username, { isFetching: false }))
  }
}

// ======================================================
// Watchers
// ======================================================

function * watchFetchUser () {
  yield takeLeadingById(TYPES.BEGIN_FETCH_USER, fetchUser, ({ data }) => data.uid)
}

function * usersSaga () {
  yield call(respawnOnError, [
    watchFetchUser
  ])
}

export default usersSaga
