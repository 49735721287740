import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import rootReducer from './reducer'
import rootSaga from './saga'
import AppContainer from './scenes/AppContainer'
import { routes } from './scenes/routes'
import createStore from 'client/shared/js/utils/store/createStore'
import { BrowserLogger } from 'client/shared/js/utils/browser'
import 'client/shared/js/components/RegisterIcons'

// ========================================================
// Create browser history
// ========================================================
const history = createBrowserHistory({
  basename: '/'
})

// ========================================================
// Route setup, this is required for hot module reload in dev mode
// ========================================================
const getRoutes = () => {
  if (__DEV__) {
    // HMR requires a fresh initialization of routes
    return require('./scenes/routes').default()
  }

  return routes()
}

// ========================================================
// Store Instantiation
// ========================================================
const initialState = window.__INITIAL_STATE__
export const { store, persistor } = createStore(initialState, history, rootReducer, 'HipHerd App', rootSaga)

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('hipherd-app-root')

let render = () => {
  ReactDOM.render(
    <AppContainer store={store} persistor={persistor} routes={getRoutes()} history={history} />,
    MOUNT_NODE
  )
}

// This code is excluded from production bundle
if (__DEV__) {
  if (module.hot) {
    // Development render functions
    const renderApp = render
    const renderError = (error) => {
      const RedBox = require('redbox-react').default

      ReactDOM.render(<RedBox error={error} />, MOUNT_NODE)
    }

    // Wrap render in try/catch
    render = () => {
      try {
        renderApp()
      } catch (error) {
        console.error(error)
        renderError(error)
      }
    }

    // Setup hot module replacement
    module.hot.accept('./scenes/routes', () =>
      setImmediate(() => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE)
        render()
      })
    )
  }
}

// ========================================================
// Set up client side logger
// ========================================================
BrowserLogger()

render()
