// import { schema, normalize, denormalize } from 'normalizr'
// import assign from 'lodash/assign'
// import { list, entityKey as listsEntityKey } from '../../lists/schema'
// import {
//   getFollowsSchema,
//   getFollowsSchemaForDenormalize
// } from 'client/shared/js/services/follows/schema'

// export const entityKey = 'boards'
// export const idAttribute = 'bid'

// export const board = new schema.Entity(entityKey, {}, { idAttribute })
// const boardDenormalize = new schema.Entity(entityKey, {}, { idAttribute })
// const boardRenormalize = new schema.Entity(entityKey, {}, { idAttribute })

// const follows = getFollowsSchema(idAttribute)
// const followsDenormalize = getFollowsSchemaForDenormalize(idAttribute)

// board.define({
//   [listsEntityKey]: [list],
//   follows
// })

// boardDenormalize.define({
//   follows: followsDenormalize
// })

// export const normalizeBoard = (boardData) => {
//   const normData = normalize(boardData, board)
//   const denormData = denormalize(normData.result, boardDenormalize, normData.entities)
//   const renormData = normalize({ [idAttribute]: denormData }, { [idAttribute]: boardRenormalize })

//   assign(normData.entities, renormData.entities)
//   normData.result = renormData.result

//   return normData
// }

// export const normalizeBoards = (boardsData) => {
//   const normData = normalize(boardsData, { [entityKey]: [board] })
//   const denormData = denormalize(normData.result, { [entityKey]: [boardDenormalize] }, normData.entities)
//   const renormData = normalize(denormData, { [entityKey]: [boardRenormalize] })

//   assign(normData.entities, renormData.entities)
//   normData.result = renormData.result

//   return normData
// }

// export const normalizeBoardAttrs = (boardId, boardAttrs = {}) => {
//   return normalizeBoard({
//     [idAttribute]: boardId,
//     ...boardAttrs
//   })
// }

// FIXME: The code above will replace below when we start normalizing users entities

import { schema, normalize } from 'normalizr'
import { list, entityKey as listsEntityKey } from '../../lists/schema'
import { place } from '../../places/schema'

export const entityKey = 'boards'
export const idAttribute = 'bid'

export const board = new schema.Entity(entityKey, {}, { idAttribute })

board.define({
  [listsEntityKey]: [list],
  search: {
    suggestions: {
      boards: [board],
      places: [place]
    }
  },
  suggestions: {
    boards: [board],
    places: [place]
  }
})

/**
 * Normalizes board data
 * @param {Object} boardData The board data
 *
 * boardData = {
 *   bid,
 *   lists: {
 *     [lid]: {
 *       lid,
 *       listPlaces: {
 *         [lpid]: {
 *           lpid
 *         }
 *       }
 *     }
 *   }
 * }
 *
 * output = {
 *   entities: {
 *     boards,
 *     listPlaces,
 *     lists
 *   },
 *   result: {
 *     bid
 *   }
 * }
 */
export const normalizeBoard = (boardData) => {
  return normalize({ [idAttribute]: boardData }, { [idAttribute]: board })
}

/**
* Normalizes boards data
* @param {Object} boardsData The boards data
*
* boardsData = {
*   boards: {
*     [bid]: {
*       bid,
*       lists: {
*         [lid]: {
*           lid,
*           listPlaces: {
*             [lpid]: {
*               lpid
*             }
*           }
*         }
*       }
*     }
*   }
* }
*
* output = {
*   entities: {
*     boards,
*     listPlaces,
*     lists
*   },
*   result: {
*     boards: [bid]
*   }
* }
*/
export const normalizeBoards = (boardsData) => {
  return normalize(boardsData, { [entityKey]: [board] })
}

/**
 * Normalizes board attributes
 * @param {String} boardId The board id
 * @param {Object} boardAttrs The board attributes
 *
 * boardAttrs = {
 *   ...any
 * }
 *
 * output = {
 *   entities: {
 *     boards: {
 *       [bid]: {
 *         bid,
 *         ...any
 *       }
 *     }
 *   },
 *   result: {
 *     bid
 *   }
 * }
 */
export const normalizeBoardAttrs = (boardId, boardAttrs = {}) => {
  return normalizeBoard({
    [idAttribute]: boardId,
    ...boardAttrs
  })
}
