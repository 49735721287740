import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { CustomInput, FormGroup, UncontrolledTooltip } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { trackEvent } from 'client/shared/js/utils/tracking'
import styles from '../../index.module.scss'

export const propTypes = {
  isPrivate: PropTypes.bool,
  togglePrivate: PropTypes.func
}

export const defaultProps = {}

const PrivateSwitchFormGroup = ({ isPrivate, togglePrivate, ...props }) => {
  const togglePrivateBoardSwitch = useCallback(() => {
    trackEvent('Board Privacy Change', { 'Change To': !isPrivate })

    togglePrivate()
  }, [isPrivate, togglePrivate])

  return (
    <PrivateSwitchFormGroupView
      {...props}
      isPrivate={isPrivate}
      togglePrivateBoardSwitch={togglePrivateBoardSwitch} />
  )
}

PrivateSwitchFormGroup.propTypes = propTypes
PrivateSwitchFormGroup.defaultProps = defaultProps

// ------------------------------------
// View component
// ------------------------------------

const viewPropTypes = {
  mountId: PropTypes.string,
  isPrivate: PropTypes.bool,
  togglePrivateBoardSwitch: PropTypes.func
}

const PrivateSwitchFormGroupView = ({ mountId, isPrivate, togglePrivateBoardSwitch }) => {
  const inputId = `boardPrivateSwitch-${mountId}`
  const iconId = `boardPrivateSwitch-info-${mountId}`

  return (
    <FormGroup className={cx(styles['form-group'], 'mb-1 d-flex justify-content-between')}>
      <CustomInput
        id={inputId}
        type='switch'
        name='boardPrivateSwitch'
        label='Private'
        className={cx(styles['private-switch'])}
        onChange={togglePrivateBoardSwitch}
        checked={isPrivate} />
      {(isPrivate) ? (
        <>
          <span className={cx(styles['private-switch-warning'])}>
            <FontAwesomeIcon
              id={iconId}
              icon={['fas', 'circle-exclamation']}
              className='icon-warning' />
          </span>
          <UncontrolledTooltip target={iconId}>
            When set to private, this board will not be publicly viewable or followable by new users.
            All existing followers will not change and you will still be able to invite collaborators.
          </UncontrolledTooltip>
        </>
      ) : null}
    </FormGroup>
  )
}

PrivateSwitchFormGroupView.propTypes = viewPropTypes

export default PrivateSwitchFormGroup
