import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { Provider, connect } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { withRouter } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import * as AuthActionCreators from 'client/shared/js/services/auth/actions'
import * as BoardsActionCreators from 'client/app/js/services/boards/actions'
import UserActionCreators from 'client/shared/js/services/user/actions'
import * as FeatureFlagActionCreators from 'client/shared/js/services/featureFlags/actions'
import { navigate } from 'client/shared/js/services/navigation/actions'
import App from './AppComponent'
import track, { trackUniversalEvent } from 'client/shared/js/utils/tracking'
import { selectors } from '../reducer'

const mapDispatchToProps = (dispatch) => ({
  authActions: bindActionCreators(AuthActionCreators, dispatch),
  boardActions: bindActionCreators(BoardsActionCreators, dispatch),
  userActions: bindActionCreators(UserActionCreators, dispatch),
  featureFlagActions: bindActionCreators(FeatureFlagActionCreators, dispatch),
  navigate: bindActionCreators(navigate, dispatch)
})

const mapStateToProps = (state, ownProps) => {
  return {
    auth: selectors.selectAuth(state),
    user: selectors.selectCurrentUser(state),
    status: selectors.selectStatus(state),
    globalMessage: selectors.selectGlobalMessage(state),
    featureFlags: selectors.selectFeatureFlags(state)
  }
}

const MainApp = withRouter(connect(mapStateToProps, mapDispatchToProps)((props) => (
  <App {...props} />))
)

const PersistorWrapper = ({ persistor, children }) => (
  persistor ? (
    <PersistGate loading={null} persistor={persistor}>{children}</PersistGate>
  ) : children
)

PersistorWrapper.propTypes = {
  persistor: PropTypes.object,
  children: PropTypes.node
}

@track({}, {
  dispatch: (data) => {
    trackUniversalEvent(data)
  }
})
class AppContainer extends Component {
  render () {
    const { routes, store, persistor, history } = this.props

    return (
      <Provider store={store}>
        <PersistorWrapper persistor={persistor}>
          <ConnectedRouter history={history}>
            <MainApp routes={routes} />
          </ConnectedRouter>
        </PersistorWrapper>
      </Provider>
    )
  }
}

AppContainer.propTypes = {
  routes: PropTypes.array,
  store: PropTypes.object,
  persistor: PropTypes.object,
  history: PropTypes.object
}

export default AppContainer
