import { UPDATE_USER } from '../types'
import { checkAndRefreshUserToken } from '../../auth/actions'
import { API_ERROR } from '../../globalMessage/types'
import userApi from '../api'

export const getUser = (userId, options) => {
  return (dispatch) => {
    return dispatch(checkAndRefreshUserToken())
      .then(() => {
        return userApi.getUser(userId, options)
      })
      .then((res) => {
        const user = res.data

        if (user) {
          dispatch({
            type: UPDATE_USER,
            data: user
          })
        } else {
          dispatch({
            type: UPDATE_USER,
            data: {
              uid: userId
            }
          })
          dispatch({
            type: API_ERROR,
            message: 'Our bad, we can\'t seem to fetch your user profile.'
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_USER,
          data: {
            uid: userId
          }
        })
        dispatch({
          type: API_ERROR,
          message: 'Our bad, we can\'t seem to fetch your user profile.'
        })
      })
  }
}
