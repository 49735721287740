import { schema, normalize } from 'normalizr'
import { idAttribute as boardIdAttribute } from '../boards/schema'
import { listPlace, entityKey as listPlacesKey } from '../listPlaces/schema'

export const entityKey = 'lists'
export const idAttribute = 'lid'

export const list = new schema.Entity(entityKey, {}, {
  idAttribute,
  processStrategy: (entity, parent) => {
    return parent[boardIdAttribute] ? (
      { ...entity, [boardIdAttribute]: parent[boardIdAttribute] }
    ) : (
      { ...entity }
    )
  }
})

list.define({
  [listPlacesKey]: [listPlace]
})

/**
 * Normalizes list data
 * @param {Object} listData The list data
 *
 * listData = {
 *   lid,
 *   listPlaces
 * }
 *
 * output = {
 *   entities: {
 *     listPlaces,
 *     lists
 *   },
 *   result: {
 *     lid
 *   }
 * }
 */
export const normalizeList = (listData) => {
  return normalize({ [idAttribute]: listData }, { [idAttribute]: list })
}

/**
 * Normalizes list attributes
 * @param {String} listId The list id
 * @param {Object} listAttrs The list data
 *
 * listAttrs = {
 *   ...any
 * }
 *
 * output = {
 *   entities: {
 *     lists: {
 *       [lid]: {
 *         ...any
 *       }
 *     }
 *   },
 *   result: {
 *     lid
 *   }
 * }
 */
export const normalizeListAttrs = (listId, listAttrs = {}) => {
  return normalizeList({
    [idAttribute]: listId,
    ...listAttrs
  })
}
