import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { persistStore } from 'redux-persist'
import analyticsMiddleware from './middleware/analytics'

export default (initialState = {}, history, rootReducer, pageName = '', rootSaga = null) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const sagaMiddleWare = createSagaMiddleware()

  const middleware = [thunk, sagaMiddleWare, routerMiddleware(history), analyticsMiddleware(pageName)]

  // ======================================================
  // Redux Logger - enable at your own discretion
  // ======================================================
  // if (__DEV__) {
  //   middleware.push(logger)
  // }

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = []

  let composeEnhancers = compose

  if (__DEV__) {
    const composeWithDevToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__

    if (typeof composeWithDevToolsExtension === 'function') {
      composeEnhancers = composeWithDevToolsExtension
    }
  }

  const reducer = rootReducer(history)

  const store = createStore(
    reducer,
    initialState,
    composeEnhancers(
      applyMiddleware(...middleware),
      ...enhancers
    )
  )

  // ======================================================
  // Persist/rehydrate whitelisted reducers
  // ======================================================
  const persistor = persistStore(store)

  // ======================================================
  // Run root saga
  // ======================================================
  if (rootSaga) sagaMiddleWare.run(rootSaga)

  return { store, persistor }
}
