import { normalizeBoardAttrs } from 'client/shared/js/services/boards/schema'
import { entityKey as backgroundPhotosKey } from './backgroundPhotos'
import { entityKey as photoLinksKey } from './photoLinks'

const entityKey = 'gallery'

export const normalizeBoardPhotos = (boardId, galleryData) => {
  return normalizeBoardAttrs(boardId, { [entityKey]: galleryData })
}

export const normalizeDeleteBoardPhotos = (boardId, deleteGalleryData) => {
  const { photos = [], backgroundPhotos = {}, photoLinks = {} } = deleteGalleryData
  const galleryData = photos.map((path) => ({ path }))

  return normalizeBoardAttrs(boardId, {
    [entityKey]: galleryData,
    [backgroundPhotosKey]: backgroundPhotos,
    [photoLinksKey]: photoLinks
  })
}
