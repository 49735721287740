import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ItemActivity from './components/ItemActivity'
import { BoardFeedMessage } from './components/BoardFeedItem'
import {
  FOLLOW_BOARD,
  BOARD_UPDATED,
  BOARD_ADD_COLLABORATOR
} from 'client/shared/js/enums/getstream/verbs'
import './FeedItem.scss'

export const Verbs = {
  [FOLLOW_BOARD]: FOLLOW_BOARD,
  [BOARD_UPDATED]: BOARD_UPDATED,
  [BOARD_ADD_COLLABORATOR]: BOARD_ADD_COLLABORATOR
}

const OthersMessage = (count) => {
  return (count === 1)
    ? (<b>{count} other</b>)
    : (<b>{count} others</b>)
}

const AggregatedFeedItem = (props) => {
  const { groupId, verb, activities, unread } = props
  const firstActivity = activities[0]
  const totalRemainingActivities = activities.length - 1
  const others = OthersMessage(totalRemainingActivities)
  const feedOptions = {
    board: firstActivity.object.data,
    onClick: props.onNavigation
  }
  let feedMessage = ''

  const deleteActivityGroup = () => {
    const activityIds = activities.map((activity) => activity.id)
    props.userActions.removeNotificationGroup(props.user.uid, groupId, activityIds)
  }

  switch (verb) {
    case FOLLOW_BOARD:
      feedMessage = (<>and {others} have favorited:</>)
      break
    case BOARD_UPDATED:
      feedMessage = (<>and {others} have updated:</>)
      break
    case BOARD_ADD_COLLABORATOR:
      feedMessage = (<>and {others} have been added as collaborators to</>)
      break
    default:
      feedMessage = ''
  }

  const message = BoardFeedMessage(feedMessage, feedOptions)
  const listClass = classNames('feed-item', { unread })

  return (message) ? (
    <li className={listClass}>
      <ItemActivity
        {...props}
        message={message}
        activity={firstActivity}
        onDelete={deleteActivityGroup} />
    </li>
  ) : null
}

AggregatedFeedItem.propTypes = {
  groupId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  verb: PropTypes.string.isRequired,
  activities: PropTypes.array.isRequired,
  userActions: PropTypes.object.isRequired,
  onNavigation: PropTypes.func,
  unread: PropTypes.bool
}

export default AggregatedFeedItem
